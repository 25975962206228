import { unionBy } from 'lodash';
import { TRANSACTIONS } from './actions';

const initialState = {
  transactions: undefined,
  hasMoreTransactions: true,
  workerTransactions: undefined,
  hasMoreWorkerTransactions: true,
  applyNewFilter: false,
  getAllTransactions: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: '' },
  },
  getWorkerTransactions: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: '' },
  },
  getClientTransactions: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: '' },
  },
  clientTransactions: undefined,
  hasMoreClientTransactions: true,
};

export default (state = initialState, { type, ...payload }) => {
  const {
    GET_ALL_TRANSACTIONS,
    GET_ALL_TRANSACTIONS_SUCCESS,
    GET_ALL_TRANSACTIONS_FAIL,
    GET_WORKER_TRANSACTIONS,
    GET_WORKER_TRANSACTIONS_SUCCESS,
    GET_WORKER_TRANSACTIONS_FAIL,
  } = TRANSACTIONS;
  const { transactions, workerTransactions, applyNewFilter, err } = payload;
  const { transactions: transactionsState, workerTransactions: workerTransactionsState } = state;

  switch (type) {
    case GET_ALL_TRANSACTIONS: {
      return {
        ...state,
        applyNewFilter,
        getAllTransactions: {
          isFetching: true,
          isSuccess: false,
          isFail: { isError: false, message: '' },
        },
      };
    }
    case GET_ALL_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        applyNewFilter: false,
        transactions:
          transactionsState && !applyNewFilter
            ? unionBy(transactionsState, transactions, '_id')
            : transactions,
        hasMoreTransactions: transactions.length === 20,
        getAllTransactions: {
          isFetching: false,
          isSuccess: true,
          isFail: { isError: false, message: '' },
        },
      };
    }
    case GET_ALL_TRANSACTIONS_FAIL: {
      return {
        ...state,
        getAllTransactions: {
          isFetching: false,
          isSuccess: false,
          isFail: { isError: true, message: err },
        },
      };
    }
    case GET_WORKER_TRANSACTIONS: {
      return {
        ...state,
        applyNewFilter,
        getWorkerTransactions: {
          isFetching: true,
          isSuccess: false,
          isFail: { isError: false, message: '' },
        },
      };
    }
    case GET_WORKER_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        workerTransactions:
          workerTransactionsState && !applyNewFilter
            ? unionBy(workerTransactionsState, workerTransactions, '_id')
            : workerTransactions,
        hasMoreWorkerTransactions: workerTransactions.length === 20,
        getWorkerTransactions: {
          isFetching: false,
          isSuccess: true,
          isFail: { isError: false, message: '' },
        },
      };
    }
    case GET_WORKER_TRANSACTIONS_FAIL: {
      return {
        ...state,
        getWorkerTransactions: {
          isFetching: false,
          isSuccess: false,
          isFail: { isError: true, message: err },
        },
      };
    }
    default:
      return state;
  }
};
