import { combineReducers } from 'redux';
import statics from './statics/reducers';
import configs from './configs/reducers';
import user from './user/reducers';
import requests from './requests/reducers';
import tickets from './tickets/reducers';
import workers from './workers/reducers';
import transactions from './transactions/reducers';
import reviews from './reviews/reducers';
import vendors from './vendors/reducers';

export default combineReducers({
  statics,
  configs,
  user,
  requests,
  tickets,
  workers,
  transactions,
  reviews,
  vendors,
});
