const ROUTES = {
  DASHBOARD: '/dashboard',
  LOGIN: '/login',
  REQUESTS: '/requests',
  SINGLE_REQUEST: '/request-details',
  TICKETS: '/tickets',
  SINGLE_TICKET: '/ticket-details',
  CLIENTS: '/clients',
  SINGLE_CLIENT: '/client-details',
  WORKERS: '/workers',
  SINGLE_WORKER: '/worker-details',
  REVIEWS: '/reviews',
  PROMO_CODES: '/promo-codes',
  NOTIFICATIONS: '/notifications',
  TRANSACTIONS_LOGS: '/transactions-logs',
  REPORTS: '/reports',
  REPORT_REQUESTS_PAYMENTS: '/reports/report-requests-payments',
  REPORT_CLIENTS_PAYMENTS: '/reports/report-clients-payments',
  REPORT_WORKERS_REQUESTS_COUNT: '/reports/report-workers-requests-count',
  REPORT_WORKERS_TRANSACTIONS: '/reports/report-workers-transactions',
  SETTINGS: '/settings',
  ADMINS: '/admins',
  OFFERS: '/offers',
  MESSAGES: '/messages',
  ACTIVITIES_LOGS: '/activityLogs',
  REPORT_CLIENTS_REQUESTS: '/reports/clients-requests',
  REPORT_PROMO_CODES: '/reports/promo-code',
  VENDORS: '/vendors',
};

export default ROUTES;
