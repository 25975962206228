import { takeEvery, put } from 'redux-saga/effects';
import Cookies from 'js-cookie';
import { Api, Urls, Status } from 'utils/api';
import { COOKIES_KEYS } from 'utils/constants';
import { CONFIGS } from './actions';

const { FETCH_CONFIGS, FETCH_CONFIGS_SUCCESS, FETCH_CONFIGS_FAIL } = CONFIGS;

function* getConfigs() {
  const { TOKEN } = COOKIES_KEYS;
  const token = Cookies.get(TOKEN);
  const { configs } = Urls;
  const url = configs.getConfigs;
  const api = new Api();
  const header = [
    {
      key: 'token',
      value: token,
    },
  ];
  const response = yield api.get(url, header);

  if (Status.isSuccess(response.status)) {
    const { response: configsObject } = response;

    yield put({
      type: FETCH_CONFIGS_SUCCESS,
      configs: configsObject,
    });
  } else {
    const {
      response: { message },
    } = response;

    yield put({
      type: FETCH_CONFIGS_FAIL,
      err: message,
    });
  }
}

function* notificationsSaga() {
  yield takeEvery(FETCH_CONFIGS, getConfigs);
}

export default notificationsSaga;
