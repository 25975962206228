export const WORKERS = {
  GET_ALL_WORKERS: 'GET_ALL_WORKERS',
  GET_ALL_WORKERS_SUCCESS: 'GET_ALL_WORKERS_SUCCESS',
  GET_ALL_WORKERS_FAIL: 'GET_ALL_WORKERS_FAIL',
  ADD_WORKER: 'ADD_WORKER',
  ADD_WORKER_SUCCESS: 'ADD_WORKER_SUCCESS',
  ADD_WORKER_FAIL: 'ADD_WORKER_FAIL',
  ADD_WORKER_RESET: 'ADD_WORKER_RESET',
  EDIT_WORKER: 'EDIT_WORKER',
  EDIT_WORKER_SUCCESS: 'EDIT_WORKER_SUCCESS',
  EDIT_WORKER_FAIL: 'EDIT_WORKER_FAIL',
  EDIT_WORKER_RESET: 'EDIT_WORKER_RESET',
  GET_WORKER_DETAILS: 'GET_WORKER_DETAILS',
  GET_WORKER_DETAILS_SUCCESS: 'GET_WORKER_DETAILS_SUCCESS',
  GET_WORKER_DETAILS_FAIL: 'GET_WORKER_DETAILS_FAIL',
  TOGGLE_ADD_NEW_WORKER_MODAL: 'TOGGLE_ADD_NEW_WORKER_MODAL',
  GET_WORKERS_REQUESTS_COUNT: 'GET_WORKERS_REQUESTS_COUNT',
  GET_WORKERS_REQUESTS_COUNT_SUCCESS: 'GET_WORKERS_REQUESTS_COUNT_SUCCESS',
  GET_WORKERS_REQUESTS_COUNT_FAIL: 'GET_WORKERS_REQUESTS_COUNT_FAIL',
  GET_WORKER_STATISTICS: 'GET_WORKER_STATISTICS',
  GET_WORKER_STATISTICS_SUCCESS: 'GET_WORKER_STATISTICS_SUCCESS',
  GET_WORKER_STATISTICS_FAIL: 'GET_WORKER_STATISTICS_FAIL',
  GET_WORKER_MOTIVATIONS_AND_PUNISHMENTS: 'GET_WORKER_MOTIVATIONS_AND_PUNISHMENTS',
  GET_WORKER_MOTIVATIONS_AND_PUNISHMENTS_SUCCESS: 'GET_WORKER_MOTIVATIONS_AND_PUNISHMENTS_SUCCESS',
  GET_WORKER_MOTIVATIONS_AND_PUNISHMENTS_FAIL: 'GET_WORKER_MOTIVATIONS_AND_PUNISHMENTS_FAIL',
  TOGGLE_CHANGE_WORKER_PASSWORD_MODAL: 'TOGGLE_CHANGE_WORKER_PASSWORD_MODAL',
  CHANGE_WORKER_PASSWORD: 'CHANGE_WORKER_PASSWORD',
  CHANGE_WORKER_PASSWORD_SUCCESS: 'CHANGE_WORKER_PASSWORD_SUCCESS',
  CHANGE_WORKER_PASSWORD_FAIL: 'CHANGE_WORKER_PASSWORD_FAIL',
  CHANGE_WORKER_PASSWORD_RESET: 'CHANGE_WORKER_PASSWORD_RESET',
};

export const getAllWorkers = (options, applyNewFilter = false) => ({
  type: WORKERS.GET_ALL_WORKERS,
  options,
  applyNewFilter,
});

export const getAllWorkersSuccess = (workers, applyNewFilter = false) => ({
  type: WORKERS.GET_ALL_WORKERS_SUCCESS,
  workers,
  applyNewFilter,
});

export const getAllWorkersFail = err => ({
  type: WORKERS.GET_ALL_WORKERS_FAIL,
  err,
});

export const addWorker = newWorkerInfo => ({
  type: WORKERS.ADD_WORKER,
  newWorkerInfo,
});

export const addWorkerSuccess = newWorkerInfo => ({
  type: WORKERS.ADD_WORKER_SUCCESS,
  newWorkerInfo,
});

export const addWorkerFail = err => ({
  type: WORKERS.ADD_WORKER_FAIL,
  err,
});

export const addWorkerReset = () => ({
  type: WORKERS.ADD_WORKER_RESET,
});

export const editWorker = (workerId, index, editParams) => ({
  type: WORKERS.EDIT_WORKER,
  workerId,
  index,
  editParams,
});

export const editWorkerSuccess = (index, editParams) => ({
  type: WORKERS.EDIT_WORKER_SUCCESS,
  index,
  editParams,
});

export const editWorkerFail = err => ({
  type: WORKERS.EDIT_WORKER_FAIL,
  err,
});

export const editWorkerReset = () => ({
  type: WORKERS.EDIT_WORKER_RESET,
});

export const addCredit = (index, addCreditInfo) => ({
  type: WORKERS.ADD_CREDIT,
  index,
  addCreditInfo,
});

export const getWorkerDetails = workerUserName => ({
  type: WORKERS.GET_WORKER_DETAILS,
  workerUserName,
});

export const getWorkerDetailsSuccess = workerDetails => ({
  type: WORKERS.GET_WORKER_DETAILS_SUCCESS,
  workerDetails,
});

export const getWorkerDetailsFail = err => ({
  type: WORKERS.GET_WORKER_DETAILS_FAIL,
  err,
});

export const toggleWorkerFormModal = mode => ({
  type: WORKERS.TOGGLE_ADD_NEW_WORKER_MODAL,
  mode,
});

export const getWorkersRequestsCount = (options, applyNewFilter: false) => ({
  type: WORKERS.GET_WORKERS_REQUESTS_COUNT,
  options,
  applyNewFilter,
});

export const getWorkersRequestsCountSuccess = (workersRequestsCount, applyNewFilter: false) => ({
  type: WORKERS.GET_WORKERS_REQUESTS_COUNT_SUCCESS,
  workersRequestsCount,
  applyNewFilter,
});

export const getWorkersRequestsCountFail = err => ({
  type: WORKERS.GET_WORKERS_REQUESTS_COUNT_FAIL,
  err,
});

export const getWorkerStatistics = workerId => ({
  type: WORKERS.GET_WORKER_STATISTICS,
  workerId,
});

export const getWorkerStatisticsSuccess = (subscribeDueDate, subscribeEndpoint) => ({
  type: WORKERS.GET_WORKER_STATISTICS_SUCCESS,
  subscribeDueDate,
  subscribeEndpoint,
});

export const getWorkerStatisticsFail = err => ({
  type: WORKERS.GET_WORKER_STATISTICS_FAIL,
  err,
});

export const getWorkerMotivationAndPunishments = workerId => ({
  type: WORKERS.GET_WORKER_MOTIVATIONS_AND_PUNISHMENTS,
  workerId,
});

export const getWorkerMotivationAndPunishmentsSuccess = (subscribeDueDate, subscribeEndpoint) => ({
  type: WORKERS.GET_WORKER_MOTIVATIONS_AND_PUNISHMENTS_SUCCESS,
  subscribeDueDate,
  subscribeEndpoint,
});

export const getWorkerMotivationAndPunishmentsFail = err => ({
  type: WORKERS.GET_WORKER_MOTIVATIONS_AND_PUNISHMENTS_FAIL,
  err,
});

export const toggleChangeWorkerPasswordModal = () => ({
  type: WORKERS.TOGGLE_CHANGE_WORKER_PASSWORD_MODAL,
});

export const changeWorkerPassword = (index, newPasswordInfo) => ({
  type: WORKERS.CHANGE_WORKER_PASSWORD,
  index,
  newPasswordInfo,
});

export const changeWorkerPasswordSuccess = () => ({
  type: WORKERS.CHANGE_WORKER_PASSWORD_SUCCESS,
});

export const changeWorkerPasswordFail = err => ({
  type: WORKERS.CHANGE_WORKER_PASSWORD_FAIL,
  err,
});

export const changeWorkerPasswordReset = () => ({
  type: WORKERS.CHANGE_WORKER_PASSWORD_RESET,
});
