import { concat } from 'lodash';
import { REVIEWS } from './actions';

const initialState = {
  reviews: undefined,
  hasMoreReviews: true,
  applyNewFilter: false,
  getAllReviews: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: '' },
  },
};

export default (state = initialState, { type, ...payload }) => {
  const { GET_ALL_REVIEWS, GET_ALL_REVIEWS_SUCCESS, GET_ALL_REVIEWS_FAIL } = REVIEWS;
  const { reviews, applyNewFilter, err } = payload;
  const { reviews: reviewsState } = state;

  switch (type) {
    case GET_ALL_REVIEWS: {
      return {
        ...state,
        getAllReviews: {
          isFetching: true,
          isSuccess: false,
          isFail: { isError: false, message: '' },
        },
      };
    }
    case GET_ALL_REVIEWS_SUCCESS: {
      return {
        ...state,
        reviews: reviewsState && !applyNewFilter ? concat(reviewsState, reviews) : reviews,
        hasMoreReviews: reviews.length === 20,
        getAllReviews: {
          isFetching: false,
          isSuccess: true,
          isFail: { isError: false, message: '' },
        },
      };
    }
    case GET_ALL_REVIEWS_FAIL: {
      return {
        ...state,
        getAllReviews: {
          isFetching: false,
          isSuccess: false,
          isFail: { isError: true, message: err },
        },
      };
    }
    default:
      return state;
  }
};
