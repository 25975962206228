import { all, fork } from 'redux-saga/effects';
import staticsSaga from './statics/sagas';
import configsSaga from './configs/sagas';
import userSaga from './user/sagas';
import requestsSaga from './requests/sagas';
import ticketsSaga from './tickets/sagas';
import workersSaga from './workers/sagas';
import transactionsSaga from './transactions/sagas';
import reviewsSaga from './reviews/sagas';
import vendorsSaga from './vendors/sagas';

const sagas = [
  staticsSaga,
  configsSaga,
  userSaga,
  requestsSaga,
  ticketsSaga,
  workersSaga,
  transactionsSaga,
  reviewsSaga,
  vendorsSaga,
];

export default function* rootSaga() {
  yield all(sagas.map(saga => fork(saga)));
}
