import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex } from '@rebass/grid';
import Cookies from 'js-cookie';
import { COOKIES_KEYS } from 'utils/constants';
import Header from 'components/Header';
import SideMenu from 'components/SideMenu';

const LayoutContainer = styled.div`
  min-height: 100vh;
  width: 100%;
`;

const ContentContainer = styled(Flex)`
  width: 100%;
  background-color: ${props => props.theme.colors.greyMedium};
`;

const FlexContainer = styled(Flex)`
  padding-top: 65px;
  flex-direction: row-reverse;
  min-height: 100vh;
`;

const Layout = props => {
  const { children, history } = props;
  const { TOKEN } = COOKIES_KEYS;
  const token = Cookies.get(TOKEN);

  return (
    <LayoutContainer>
      {token && (
        <Header
          onLogoClick={() => {
            history.push('/');
          }}
          {...props}
        />
      )}
      <FlexContainer>
        <SideMenu {...props} />
        <ContentContainer
          px={[3, 3, 3, 3, 3, 3]}
          pb={3}
          mr={['35px', '75px', '75px', '75px', '75px']}
        >
          {children}
        </ContentContainer>
      </FlexContainer>
    </LayoutContainer>
  );
};

Layout.propTypes = {
  user: PropTypes.shape({}),
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  history: PropTypes.shape({
    action: PropTypes.string,
    block: PropTypes.func,
    createHref: PropTypes.func,
    go: PropTypes.func,
    goBack: PropTypes.func,
    goForward: PropTypes.func,
    length: PropTypes.number,
    listen: PropTypes.func,
    location: PropTypes.shape({
      hash: PropTypes.string,
      pathname: PropTypes.string,
      search: PropTypes.string,
    }),
    push: PropTypes.func,
    replace: PropTypes.func,
  }).isRequired,
};

Layout.defaultProps = {
  user: undefined,
};

export default Layout;
