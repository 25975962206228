import { takeEvery, put } from 'redux-saga/effects';
import Cookies from 'js-cookie';
import { Api, Urls, Status } from 'utils/api';
import { COOKIES_KEYS } from 'utils/constants';
import { REVIEWS } from './actions';

const { GET_ALL_REVIEWS, GET_ALL_REVIEWS_SUCCESS, GET_ALL_REVIEWS_FAIL } = REVIEWS;

function* getAllReviews(payload) {
  const { options, applyNewFilter } = payload;
  const { TOKEN } = COOKIES_KEYS;
  const token = Cookies.get(TOKEN);
  const { reviews } = Urls;
  const api = new Api();
  const header = [
    {
      key: 'token',
      value: token,
    },
  ];

  let url = reviews.general;

  Object.keys(options).forEach((key, index) => {
    if (index === 0) {
      url = `${url}?${key}=${options[key]}`;
    } else {
      url = `${url}&${key}=${options[key]}`;
    }
  });

  const response = yield api.get(url, header);

  if (Status.isSuccess(response.status)) {
    const { response: allReviews } = response;

    yield put({
      type: GET_ALL_REVIEWS_SUCCESS,
      reviews: allReviews,
      applyNewFilter,
    });
  } else {
    const {
      response: { message },
    } = response;

    yield put({
      type: GET_ALL_REVIEWS_FAIL,
      err: message,
    });
  }
}

function* reviewsSaga() {
  yield takeEvery(GET_ALL_REVIEWS, getAllReviews);
}

export default reviewsSaga;
