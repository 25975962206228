export const TRANSACTIONS = {
  GET_ALL_TRANSACTIONS: 'GET_ALL_TRANSACTIONS',
  GET_ALL_TRANSACTIONS_SUCCESS: 'GET_ALL_TRANSACTIONS_SUCCESS',
  GET_ALL_TRANSACTIONS_FAIL: 'GET_ALL_TRANSACTIONS_FAIL',
  GET_WORKER_TRANSACTIONS: 'GET_WORKER_TRANSACTIONS',
  GET_WORKER_TRANSACTIONS_SUCCESS: 'GET_WORKER_TRANSACTIONS_SUCCESS',
  GET_WORKER_TRANSACTIONS_FAIL: 'GET_WORKER_TRANSACTIONS_FAIL',
};

export const getAllTransactions = (options, applyNewFilter: false) => ({
  type: TRANSACTIONS.GET_ALL_TRANSACTIONS,
  options,
  applyNewFilter,
});

export const getAllTransactionsSuccess = (transactions, applyNewFilter: false) => ({
  type: TRANSACTIONS.GET_ALL_TRANSACTIONS_SUCCESS,
  transactions,
  applyNewFilter,
});

export const getAllTransactionsFail = err => ({
  type: TRANSACTIONS.GET_ALL_TRANSACTIONS_FAIL,
  err,
});

export const getWorkerTransactions = (options, applyNewFilter: false) => ({
  type: TRANSACTIONS.GET_WORKER_TRANSACTIONS,
  options,
  applyNewFilter,
});

export const getWorkerTransactionsSuccess = (transactions, applyNewFilter: false) => ({
  type: TRANSACTIONS.GET_WORKER_TRANSACTIONS_SUCCESS,
  transactions,
  applyNewFilter,
});

export const getWorkerTransactionsFail = err => ({
  type: TRANSACTIONS.GET_WORKER_TRANSACTIONS_FAIL,
  err,
});