export const TICKETS = {
  GET_ALL_TICKETS: 'GET_ALL_TICKETS',
  GET_ALL_TICKETS_SUCCESS: 'GET_ALL_TICKETS_SUCCESS',
  GET_ALL_TICKETS_FAIL: 'GET_ALL_TICKETS_FAIL',
  GET_TICKET_DETAILS: 'GET_TICKET_DETAILS',
  GET_TICKET_DETAILS_SUCCESS: 'GET_TICKET_DETAILS_SUCCESS',
  GET_TICKET_DETAILS_FAIL: 'GET_TICKET_DETAILS_FAIL',
  GET_TICKETS_COUNT_IN_EACH_STATUS: {
    LOAD: 'GET_TICKETS_COUNT_IN_EACH_STATUS_LOAD',
    SUCCESS: 'GET_TICKETS_COUNT_IN_EACH_STATUS_SUCCESS',
    FAIL: 'GET_TICKETS_COUNT_IN_EACH_STATUS_FAIL',
  },
};

export const getAllTickets = (options, applyNewFilter = false) => ({
  type: TICKETS.GET_ALL_TICKETS,
  options,
  applyNewFilter,
});

export const getAllTicketsSuccess = (tickets, applyNewFilter = false) => ({
  type: TICKETS.GET_ALL_TICKETS_SUCCESS,
  tickets,
  applyNewFilter,
});

export const getAllTicketsFail = err => ({
  type: TICKETS.GET_ALL_TICKETS_FAIL,
  err,
});

export const getTicketDetails = ticketId => ({
  type: TICKETS.GET_TICKET_DETAILS,
  ticketId,
});

export const getTicketDetailsSuccess = ticketDetails => ({
  type: TICKETS.GET_TICKET_DETAILS_SUCCESS,
  ticketDetails,
});

export const getTicketDetailsFail = err => ({
  type: TICKETS.GET_TICKET_DETAILS_FAIL,
  err,
});

export const getTicketsCountInEachStatus = options => ({
  type: TICKETS.GET_TICKETS_COUNT_IN_EACH_STATUS.LOAD,
  options,
});

export const getTicketsCountInEachStatusSuccess = ticketsCountInEachStatus => ({
  type: TICKETS.GET_TICKETS_COUNT_IN_EACH_STATUS.SUCCESS,
  ticketsCountInEachStatus,
});

export const getTicketsCountInEachStatusFail = err => ({
  type: TICKETS.GET_TICKETS_COUNT_IN_EACH_STATUS.FAIL,
  err,
});
