import { takeEvery, takeLatest, put } from 'redux-saga/effects';
import Cookies from 'js-cookie';
import { Api, Urls, Status } from 'utils/api';
import { COOKIES_KEYS } from 'utils/constants';
import { TRANSACTIONS } from './actions';

const {
  GET_ALL_TRANSACTIONS,
  GET_ALL_TRANSACTIONS_SUCCESS,
  GET_ALL_TRANSACTIONS_FAIL,
  GET_WORKER_TRANSACTIONS,
  GET_WORKER_TRANSACTIONS_SUCCESS,
  GET_WORKER_TRANSACTIONS_FAIL,
} = TRANSACTIONS;

function* getAllTransactions(payload) {
  const { options, applyNewFilter } = payload;
  const { TOKEN } = COOKIES_KEYS;
  const token = Cookies.get(TOKEN);
  const { transactions } = Urls;
  const api = new Api();
  const header = [
    {
      key: 'token',
      value: token,
    },
  ];

  let url = transactions.getAllTransactions;
  Object.keys(options).forEach((key, index) => {
    if (typeof options[key] === 'object' && options[key].constructor === Object) {
      Object.keys(options[key]).forEach((nestedKey, nestedIndex) => {
        if (nestedIndex === 0 && !url.includes('?')) {
          url = `${url}?${nestedKey}=${options[key][nestedKey]}`;
        } else {
          url = `${url}&${nestedKey}=${options[key][nestedKey]}`;
        }
      });
      return true;
    }

    if (index === 0 && !url.includes('?')) {
      url = `${url}?${key}=${options[key]}`;
    }

    url = `${url}&${key}=${options[key]}`;

    return true;
  });
  const response = yield api.get(url, header);

  if (Status.isSuccess(response.status)) {
    const { response: allTransactions } = response;

    yield put({
      type: GET_ALL_TRANSACTIONS_SUCCESS,
      transactions: allTransactions,
      applyNewFilter,
    });
  } else {
    const {
      response: { message },
    } = response;

    yield put({
      type: GET_ALL_TRANSACTIONS_FAIL,
      err: message,
    });
  }
}

function* getWorkerTransactions(payload) {
  const { options, applyNewFilter } = payload;
  const { TOKEN } = COOKIES_KEYS;
  const token = Cookies.get(TOKEN);
  const { transactions } = Urls;
  const api = new Api();
  const header = [
    {
      key: 'token',
      value: token,
    },
  ];

  let url = transactions.getAllTransactions;

  Object.keys(options).forEach((key, index) => {
    if (index === 0) {
      url = `${url}?${key}=${options[key]}`;
    } else {
      url = `${url}&${key}=${options[key]}`;
    }
  });

  const response = yield api.get(url, header);

  if (Status.isSuccess(response.status)) {
    const { response: allTransactions } = response;

    yield put({
      type: GET_WORKER_TRANSACTIONS_SUCCESS,
      workerTransactions: allTransactions,
      applyNewFilter,
    });
  } else {
    const {
      response: { message },
    } = response;

    yield put({
      type: GET_WORKER_TRANSACTIONS_FAIL,
      err: message,
    });
  }
}

function* userSaga() {
  yield takeLatest(GET_ALL_TRANSACTIONS, getAllTransactions);
  yield takeEvery(GET_WORKER_TRANSACTIONS, getWorkerTransactions);
}

export default userSaga;
