import { USER } from './actions';

const initialState = {
  user: undefined,
  admins: undefined,
  hasMoreAdmins: true,
  applyNewFilter: false,
  login: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: '' },
  },
  getAllAdmins: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: '' },
  },
  createAdmin: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: '' },
  },
  deleteAdmin: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: '' },
  },
  changeAdminPassword: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: '' },
  },
  addPermissionsToUser: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: '' },
  },
  exportFile: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: '' },
  },
  addEditAdminModal: { isOpen: false },
  changeAdminPasswordModal: { isOpen: false },
};

export default (state = initialState, { type, ...payload }) => {
  const {
    LOGIN,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    EXPORT_FILE,
    EXPORT_FILE_SUCCESS,
    EXPORT_FILE_FAIL,
  } = USER;
  const { userCredentials, user, err } = payload;

  switch (type) {
    case LOGIN: {
      return {
        ...state,
        userCredentials,
        login: {
          isFetching: true,
          isSuccess: false,
          isFail: { isError: false, message: '' },
        },
      };
    }
    case LOGIN_SUCCESS: {
      return {
        ...state,
        user,
        login: {
          isFetching: false,
          isSuccess: true,
          isFail: { isError: false, message: '' },
        },
      };
    }
    case LOGIN_FAIL: {
      return {
        ...state,
        login: {
          isFetching: false,
          isSuccess: false,
          isFail: { isError: true, message: err },
        },
      };
    }
    case EXPORT_FILE: {
      return {
        ...state,
        exportFile: {
          isFetching: true,
          isSuccess: false,
          isFail: { isError: false, message: '' },
        },
      };
    }
    case EXPORT_FILE_SUCCESS: {
      return {
        ...state,
        exportFile: {
          isFetching: false,
          isSuccess: true,
          isFail: { isError: false, message: '' },
        },
      };
    }
    case EXPORT_FILE_FAIL: {
      return {
        ...state,
        exportFile: {
          isFetching: false,
          isSuccess: false,
          isFail: { isError: true, message: err },
        },
      };
    }
    default:
      return state;
  }
};
