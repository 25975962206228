export const FONT_TYPES = {
  MEGA_TITLE: 'megaTitle',
  SUPER_TITLE: 'superTitle',
  BIG_TITLE: 'bigTitle',
  TITLE: 'title',
  HEADING: 'heading',
  SUBHEADING: 'subheading',
  BODY: 'body',
  CAPTION: 'caption',
};

export const FONT_SIZES = {
  // Breakpoints: ['400px', '576px', '786px', '992px']
  [FONT_TYPES.MEGA_TITLE]: ['68px', '68px', '64px', '64px'],
  [FONT_TYPES.SUPER_TITLE]: ['34px', '34px', '32px', '32px'],
  [FONT_TYPES.BIG_TITLE]: ['28px', '28px', '26px', '26px'],
  [FONT_TYPES.TITLE]: ['22px', '22px', '20px', '20px'],
  [FONT_TYPES.HEADING]: ['20px', '20px', '18px', '18px'],
  [FONT_TYPES.SUBHEADING]: ['18px', '18px', '16px', '16px'],
  [FONT_TYPES.BODY]: ['16px', '16px', '14px', '14px'],
  [FONT_TYPES.CAPTION]: ['14px', '14px', '12px', '12px'],
};

export const FONT_WEIGHTS = {
  BOLD: 'bold',
  SEMI_BOLD: 600,
  NORMAL: 500,
  LIGHT: 400,
};

export const LINE_HEIGHTS = {
  [FONT_TYPES.MEGA_TITLE]: '68px',
  [FONT_TYPES.SUPER_TITLE]: '32px',
  [FONT_TYPES.BIG_TITLE]: '24px',
  [FONT_TYPES.TITLE]: '22px',
  [FONT_TYPES.HEADING]: '20px',
  [FONT_TYPES.SUBHEADING]: '18px',
  [FONT_TYPES.BODY]: '16px',
  [FONT_TYPES.CAPTION]: '14px',
};
