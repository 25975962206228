import { unionBy } from 'lodash';
import { TICKETS } from './actions';

const initialState = {
  tickets: undefined,
  hasMoreTickets: true,
  applyNewFilter: false,
  ticketDetails: undefined,
  getAllTickets: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: '' },
  },
  getTicketDetails: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: '' },
  },
  getTicketsCountInEachStatus: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: '' },
  },
};

export default (state = initialState, { type, ...payload }) => {
  const {
    GET_ALL_TICKETS,
    GET_ALL_TICKETS_SUCCESS,
    GET_ALL_TICKETS_FAIL,
    GET_TICKET_DETAILS,
    GET_TICKET_DETAILS_SUCCESS,
    GET_TICKET_DETAILS_FAIL,
    GET_TICKETS_COUNT_IN_EACH_STATUS,
  } = TICKETS;
  const { tickets, applyNewFilter, ticketDetails, ticketsCountInEachStatus, err } = payload;
  const { tickets: ticketsState } = state;

  switch (type) {
    case GET_ALL_TICKETS: {
      return {
        ...state,
        applyNewFilter,
        getAllTickets: {
          isFetching: true,
          isSuccess: false,
          isFail: { isError: false, message: '' },
        },
      };
    }
    case GET_ALL_TICKETS_SUCCESS: {
      return {
        ...state,
        tickets: ticketsState && !applyNewFilter ? unionBy(ticketsState, tickets, '_id') : tickets,
        hasMoreTickets: tickets.length === 20,
        getAllTickets: {
          isFetching: false,
          isSuccess: true,
          isFail: { isError: false, message: '' },
        },
      };
    }
    case GET_ALL_TICKETS_FAIL: {
      return {
        ...state,
        getAllTickets: {
          isFetching: false,
          isSuccess: false,
          isFail: { isError: true, message: err },
        },
      };
    }
    case GET_TICKET_DETAILS: {
      return {
        ...state,
        getTicketDetails: {
          isFetching: true,
          isSuccess: false,
          isFail: { isError: false, message: '' },
        },
      };
    }
    case GET_TICKET_DETAILS_SUCCESS: {
      return {
        ...state,
        ticketDetails,
        getTicketDetails: {
          isFetching: false,
          isSuccess: true,
          isFail: { isError: false, message: '' },
        },
      };
    }
    case GET_TICKET_DETAILS_FAIL: {
      return {
        ...state,
        getTicketDetails: {
          isFetching: false,
          isSuccess: false,
          isFail: { isError: true, message: err },
        },
      };
    }
    case GET_TICKETS_COUNT_IN_EACH_STATUS.LOAD: {
      return {
        ...state,
        getTicketsCountInEachStatus: {
          isFetching: true,
          isSuccess: false,
          isFail: { isError: false, message: '' },
        },
      };
    }
    case GET_TICKETS_COUNT_IN_EACH_STATUS.SUCCESS: {
      return {
        ...state,
        ticketsCountInEachStatus,
        getTicketsCountInEachStatus: {
          isFetching: false,
          isSuccess: true,
          isFail: { isError: false, message: '' },
        },
      };
    }
    case GET_TICKETS_COUNT_IN_EACH_STATUS.FAIL: {
      return {
        ...state,
        getTicketsCountInEachStatus: {
          isFetching: false,
          isSuccess: false,
          isFail: { isError: true, message: err },
        },
      };
    }
    default:
      return state;
  }
};
