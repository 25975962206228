export const STATICS = {
  FETCH_TRANSACTIONS_CLASSIFICATION: 'FETCH_TRANSACTIONS_CLASSIFICATION',
  FETCH_TRANSACTIONS_CLASSIFICATION_SUCCESS: 'FETCH_TRANSACTIONS_CLASSIFICATION_SUCCESS',
  FETCH_TRANSACTIONS_CLASSIFICATION_FAIL: 'FETCH_TRANSACTIONS_CLASSIFICATION_FAIL',
  FETCH_FOLLOW_UPS_LIST: 'FETCH_FOLLOW_UPS_LIST',
  FETCH_FOLLOWUPS_LIST_SUCCESS: 'FETCH_FOLLOWUPS_LIST_SUCCESS',
  FETCH_FOLLOWUPS_LIST_FAIL: 'FETCH_FOLLOWUPS_LIST_FAIL',
};

export const fetchTransactionsClassification = () => ({
  type: STATICS.FETCH_TRANSACTIONS_CLASSIFICATION,
});

export const fetchTransactionsClassificationSuccess = transactionsClassification => ({
  type: STATICS.FETCH_TRANSACTIONS_CLASSIFICATION_SUCCESS,
  transactionsClassification,
});

export const fetchTransactionsClassificationFail = error => ({
  type: STATICS.FETCH_TRANSACTIONS_CLASSIFICATION_FAIL,
  error,
});

export const fetchFollowUpsList = () => ({
  type: STATICS.FETCH_FOLLOW_UPS_LIST,
});

export const fetchFollowUpsListSuccess = followUpsLis => ({
  type: STATICS.FETCH_FOLLOWUPS_LIST_SUCCESS,
  followUpsLis,
});

export const fetchFollowUpsListFail = error => ({
  type: STATICS.FETCH_FOLLOWUPS_LIST_FAIL,
  error,
});
