import { unionBy, findIndex } from 'lodash';
import update from 'immutability-helper';
import { REQUESTS } from './actions';

const initialState = {
  requests: undefined,
  hasMoreRequests: true,
  applyNewFilter: false,
  requestDetails: undefined,
  requestsPayments: undefined,
  hasMoreRequestsPayments: true,
  getAllRequests: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: '' },
  },
  getRequestDetails: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: '' },
  },
  getRequestsPayments: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: '' },
  },
  opsCommentsModal: { isOpen: false },
  socketIoStatus: '',
  requestId: undefined,
  getReconditioningRequestsCount: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: '' },
  },
  reconditioningRequestsCount: undefined,
};

export default (state = initialState, { type, ...payload }) => {
  const {
    GET_ALL_REQUESTS,
    GET_ALL_REQUESTS_SUCCESS,
    GET_ALL_REQUESTS_FAIL,
    GET_REQUEST_DETAILS_BY_PRETTY_ID,
    GET_REQUEST_DETAILS_BY_PRETTY_ID_SUCCESS,
    GET_REQUEST_DETAILS_BY_PRETTY_ID_FAIL,
    GET_REQUESTS_PAYMENTS,
    GET_REQUESTS_PAYMENTS_SUCCESS,
    GET_REQUESTS_PAYMENTS_FAIL,
    NEW_REQUEST_CREATED,
    CONNECT_TO_SOCKET_IO,
    SOCKET_IO_SERVER_OFF,
    SOCKET_IO_SERVER_ON,
    REQUEST_UPDATED,
    GET_RECONDITIONING_REQUESTS_COUNT,
    GET_RECONDITIONING_REQUESTS_COUNT_SUCCESS,
    GET_RECONDITIONING_REQUESTS_COUNT_FAIL,
  } = REQUESTS;
  const {
    requests,
    requestsPayments,
    applyNewFilter,
    requestDetails,
    newRequest,
    updatedRequest,
    reconditioningRequestsCount,
    err,
  } = payload;
  const { requests: requestsState, requestsPayments: requestsPaymentsState } = state;

  switch (type) {
    case GET_ALL_REQUESTS: {
      return {
        ...state,
        applyNewFilter,
        getAllRequests: {
          isFetching: true,
          isSuccess: false,
          isFail: { isError: false, message: '' },
        },
      };
    }
    case GET_ALL_REQUESTS_SUCCESS: {
      return {
        ...state,
        requests:
          requestsState && !applyNewFilter ? unionBy(requestsState, requests, '_id') : requests,
        hasMoreRequests: requests.length === 20,
        getAllRequests: {
          isFetching: false,
          isSuccess: true,
          isFail: { isError: false, message: '' },
        },
      };
    }
    case GET_ALL_REQUESTS_FAIL: {
      return {
        ...state,
        getAllRequests: {
          isFetching: false,
          isSuccess: false,
          isFail: { isError: true, message: err },
        },
      };
    }
    case GET_REQUEST_DETAILS_BY_PRETTY_ID: {
      return {
        ...state,
        getRequestDetails: {
          isFetching: true,
          isSuccess: false,
          isFail: { isError: false, message: '' },
        },
      };
    }
    case GET_REQUEST_DETAILS_BY_PRETTY_ID_SUCCESS: {
      return {
        ...state,
        requestDetails,
        getRequestDetails: {
          isFetching: false,
          isSuccess: true,
          isFail: { isError: false, message: '' },
        },
      };
    }
    case GET_REQUEST_DETAILS_BY_PRETTY_ID_FAIL: {
      return {
        ...state,
        getRequestDetails: {
          isFetching: false,
          isSuccess: false,
          isFail: { isError: true, message: err },
        },
      };
    }
    case GET_REQUESTS_PAYMENTS: {
      return {
        ...state,
        applyNewFilter,
        getRequestsPayments: {
          isFetching: true,
          isSuccess: false,
          isFail: { isError: false, message: '' },
        },
      };
    }
    case GET_REQUESTS_PAYMENTS_SUCCESS: {
      return {
        ...state,
        applyNewFilter: false,
        requestsPayments:
          requestsPaymentsState && !applyNewFilter
            ? unionBy(requestsPaymentsState, requestsPayments, '_id')
            : requestsPayments,
        hasMoreRequestsPayments: requestsPayments.length === 20,
        getRequestsPayments: {
          isFetching: false,
          isSuccess: true,
          isFail: { isError: false, message: '' },
        },
      };
    }
    case GET_REQUESTS_PAYMENTS_FAIL: {
      return {
        ...state,
        getRequestsPayments: {
          isFetching: false,
          isSuccess: false,
          isFail: { isError: true, message: err },
        },
      };
    }
    case CONNECT_TO_SOCKET_IO: {
      return {
        ...state,
        socketIoStatus: 'جاري الاتصال بالسرفر',
      };
    }
    case SOCKET_IO_SERVER_OFF: {
      return {
        ...state,
        socketIoStatus: 'غير قادر على الاتصال بى السرفر',
      };
    }
    case SOCKET_IO_SERVER_ON: {
      return {
        ...state,
        socketIoStatus: 'متصل بالسرفر',
      };
    }
    case NEW_REQUEST_CREATED: {
      let newRequestsState = requestsState;
      const cachedFilterState = JSON.parse(sessionStorage.getItem('requestsFilterState'));

      if (cachedFilterState) {
        const { filterQueries: cachedFilterQueries } = cachedFilterState;

        if (
          (cachedFilterQueries.statuses === undefined ||
            cachedFilterQueries.statuses === '' ||
            cachedFilterQueries.statuses.split(',').includes('pending')) &&
          (cachedFilterQueries.types === undefined ||
            cachedFilterQueries.types === '' ||
            cachedFilterQueries.types.split(',').includes(newRequest.field))
        ) {
          newRequestsState = unionBy([newRequest], requestsState);
        }
      }

      return {
        ...state,
        requests: newRequestsState,
      };
    }
    case REQUEST_UPDATED: {
      const foundIndex = findIndex(
        requestsState,
        requestState => requestState._id === updatedRequest._id,
      );
      let updatedRequestByIndex =
        foundIndex !== undefined &&
        update(requestsState, {
          [foundIndex]: { $merge: updatedRequest },
        });

      let updatedRequests = foundIndex !== undefined ? updatedRequestByIndex : requestsState;

      if (updatedRequest.customer) {
        updatedRequestByIndex = update(updatedRequests, {
          $apply: items =>
            items.map(item => {
              if (item.customer._id === updatedRequest.customer._id) {
                return update(item, {
                  $merge: { customer: updatedRequest.customer },
                });
              }
              return item;
            }),
        });
      }

      updatedRequests = foundIndex !== undefined ? updatedRequestByIndex : requestsState;
      return {
        ...state,
        requests: updatedRequests,
      };
    }
    case GET_RECONDITIONING_REQUESTS_COUNT: {
      return {
        ...state,
        getReconditioningRequestsCount: {
          isFetching: true,
          isSuccess: false,
          isFail: { isError: false, message: '' },
        },
      };
    }
    case GET_RECONDITIONING_REQUESTS_COUNT_SUCCESS: {
      return {
        ...state,
        reconditioningRequestsCount,
        getReconditioningRequestsCount: {
          isFetching: false,
          isSuccess: true,
          isFail: { isError: false, message: '' },
        },
      };
    }
    case GET_RECONDITIONING_REQUESTS_COUNT_FAIL: {
      return {
        ...state,
        getReconditioningRequestsCount: {
          isFetching: false,
          isSuccess: false,
          isFail: { isError: true, message: err },
        },
      };
    }
    default:
      return state;
  }
};
