import { takeEvery, put } from 'redux-saga/effects';
import Cookies from 'js-cookie';
import { Api, Urls, Status } from 'utils/api';
import { COOKIES_KEYS } from 'utils/constants';
import { STATICS } from './actions';

const {
  FETCH_TRANSACTIONS_CLASSIFICATION,
  FETCH_TRANSACTIONS_CLASSIFICATION_SUCCESS,
  FETCH_TRANSACTIONS_CLASSIFICATION_FAIL,
  FETCH_FOLLOW_UPS_LIST,
  FETCH_FOLLOWUPS_LIST_SUCCESS,
  FETCH_FOLLOWUPS_LIST_FAIL,
} = STATICS;

function* fetchTransactionsClassification() {
  const { TOKEN } = COOKIES_KEYS;
  const token = Cookies.get(TOKEN);
  const { transactions } = Urls;
  const api = new Api();
  const url = transactions.getClassification;
  const header = [
    {
      key: 'token',
      value: token,
    },
  ];
  const response = yield api.get(url, header);
  const responseStatus = response.status;

  if (Status.isSuccess(responseStatus)) {
    yield put({
      type: FETCH_TRANSACTIONS_CLASSIFICATION_SUCCESS,
      transactionsClassification: response.response,
    });
  } else {
    const {
      response: { message },
    } = response;

    yield put({
      type: FETCH_TRANSACTIONS_CLASSIFICATION_FAIL,
      error: message,
    });
  }
}

function* fetchFollowUpsList() {
  const api = new Api();
  const url = Urls.configs.getConfigs;
  const header = [
    {
      key: 'token',
      value: Cookies.get(COOKIES_KEYS.TOKEN),
    },
  ];
  const response = yield api.get(url, header);
  const responseStatus = response.status;

  if (Status.isSuccess(responseStatus)) {
    const {
      enums: { followUpsRequest },
    } = response.response;

    yield put({
      type: FETCH_FOLLOWUPS_LIST_SUCCESS,
      followUpsList: followUpsRequest,
    });
  } else {
    const {
      response: { message },
    } = response;

    yield put({
      type: FETCH_FOLLOWUPS_LIST_FAIL,
      error: message,
    });
  }
}

function* staticsSaga() {
  yield takeEvery(FETCH_TRANSACTIONS_CLASSIFICATION, fetchTransactionsClassification);
  yield takeEvery(FETCH_FOLLOW_UPS_LIST, fetchFollowUpsList);
}

export default staticsSaga;
