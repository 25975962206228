const COOKIES_KEYS = {
  TOKEN: 'TK',
  PLAIN_USER: 'US',
};

const USER_ROLES = {
  ADMIN: 'admin',
  CUSTOMER: 'customer',
  WORKER: 'worker',
  VENDOR: 'vendor',
};

const USER_TARGETS = {
  customer: {
    ar: 'العملاء',
    en: 'customers',
  },
  worker: {
    ar: 'الفنيين',
    en: 'workers',
  },
};

const OPERATING_SYSTEMS = {
  android: {
    ar: 'أندرويد',
    en: 'android',
  },
  ios: {
    ar: 'أي أو أس',
    en: 'ios',
  },
};

const CONFIRM_CHOICE = {
  true: {
    ar: 'نعم',
    en: 'Yes',
  },
  false: {
    ar: 'لا',
    en: 'No',
  },
};

const FIELDS = {
  plumbing: {
    ar: 'سباكة',
    en: 'Plumbing',
  },
  electrical: {
    ar: 'كهرباء',
    en: 'Electrical',
  },
  'air-conditioning': {
    ar: 'تكييف',
    en: 'Air conditioning',
  },
  'satellite-tv': {
    ar: 'ستلايات',
    en: 'Satellite TV',
  },
  'furniture-transportation': {
    ar: 'نقل أثاث',
    en: 'Furniture transportation',
  },
  'home-appliances': {
    ar: 'أجهزة منزلية',
    en: 'Home appliances',
  },
  carpentry: {
    ar: 'نجارة',
    en: 'Carpentry',
  },
  painting: {
    ar: 'دهان',
    en: 'Painting',
  },
  cleaning: {
    ar: 'تنظيف و ابادة حشرات',
    en: 'Cleaning and Bug-extermination',
  },
  'water-saving': {
    ar: 'ترشيد مياه',
    en: 'Water Saving',
  },
  paved: {
    ar: 'تبليط',
    en: 'Paved',
  },
  cameras: {
    ar: 'كاميرات',
    en: 'Cameras',
  },
  mobiles: {
    ar: 'جوالات',
    en: 'Mobiles',
  },
  reconditioning: {
    ar: 'ترميم',
    en: 'reconditioning',
  },
  parquet: {
    ar: 'ارضية خشبية',
    en: 'parquet',
  },
  'furniture-upholstery': {
    ar: 'لمفروشات والأثاث',
    en: 'furniture-upholstery',
  },
  aluminum: {
    ar: 'الألومنيوم',
    en: 'aluminum',
  },
  construction: {
    ar: 'اعمال بناء',
    en: 'construction',
  },
  'gypsum-board': {
    ar: 'ألواح الجبس',
    en: 'gypsum-board',
  },
  elevadores: {
    ar: 'المصاعد',
    en: 'elevadores',
  },
};

const CITIES = {
  Jeddah: {
    ar: 'جدة',
    en: 'Jeddah',
  },
  Mecca: {
    ar: 'مكة',
    en: 'Mecca',
  },
  Riyadh: {
    ar: 'الرياض',
    en: 'Riyadh',
  },
  Medina: {
    ar: 'المدينة المنورة',
    en: 'Medina',
  },
  Dammam: {
    ar: 'الدمام',
    en: 'Dammam',
  },
  'Al Khobar': {
    ar: 'الخبر',
    en: 'Al Khobar',
  },
  Dhahran: {
    ar: 'الظهران',
    en: 'Dhahran',
  },
  Abha: {
    ar: 'أبها',
    en: 'Abha',
  },
  'Khamis Mushait': {
    ar: 'خميس مشيط',
    en: 'Khamis Mushait',
  },
  '': {
    ar: 'لا يوجد مدينة',
    en: 'city not exist',
  },
};

const USER_STATUSES = [
  {
    filterKey: 'suspended',
    value: 'true',
    name: 'suspended',
    ar: 'متوقف',
    en: 'Suspended',
  },
  {
    filterKey: 'suspended',
    value: 'false',
    name: 'notSuspended',
    ar: 'غير متوقف',
    en: 'Not Suspended',
  },
  {
    filterKey: 'active',
    value: 'true',
    name: 'active',
    ar: 'نشط',
    en: 'ِActive',
  },
  {
    filterKey: 'active',
    value: 'false',
    name: 'notActive',
    ar: 'غير نشط',
    en: 'ِNot Active',
  },
];

const REQUESTS_STATUSES = {
  pending: {
    ar: 'في انتظار اعتماد الطلب',
    en: 'Pending',
  },
  canceled: {
    ar: 'ملغى',
    en: 'Canceled',
  },
  done: {
    ar: 'مكتمل',
    en: 'Done',
  },
  active: {
    ar: 'نشط',
    en: 'Active',
  },
  'timed-out': {
    ar: 'الغي لنفاذ الوقت',
    en: 'Timed out',
  },
  rated: {
    ar: 'تم تقييمه',
    en: 'Rated',
  },
  reviewed: {
    ar: 'تمت مراجعته',
    en: 'Reviewed',
  },
  postponed: {
    ar: 'مؤجل',
    en: 'Postponed',
  },
};

const TICKETS_STATUS = {
  Open: {
    ar: 'جارية',
    en: 'Open',
  },
  Solving: {
    ar: 'جاري الحل',
    en: 'Solving',
  },
  Solved: {
    ar: 'تم الحل',
    en: 'Solved',
  },
};

const PAYMENT_STATUS = {
  true: {
    ar: 'تم الدفع',
    en: 'Done',
  },
  false: {
    ar: 'لم يتم الدفع',
    en: 'Not paid',
  },
};

const PAYMENT_METHOD = {
  cash: {
    ar: 'نقدي',
    en: 'Cash',
  },
};

const CANCELLATION_REASONS = {
  cancelledByAdmin: {
    ar: 'تم الإلغاء من قبل المشرف',
    en: 'Cancelled by admin',
  },
  insufficientAppliedTechnicians: {
    ar: 'لا يوجد عدد كافي من الفنيين المقدمين',
    en: 'No enough technicians applied',
  },
  justCheckingTheApp: {
    ar: 'تجربة التطبيق',
    en: 'Trying the app',
  },
  couldNotUnderstandTheApp: {
    ar: 'عدم المقدرة على فهم عمل التطبيق',
    en: 'Could not understand the app workflow',
  },
  highPrice: {
    ar: 'السعر مرتفع',
    en: 'High price',
  },
  technicianNotSuitable: {
    ar: 'الفني غير مناسب',
    en: 'Technician is not suitable',
  },
  technicianWasANoShow: {
    ar: 'تأخر الفني عن الموعد',
    en: 'Technician did not show up',
  },
  technicianRequestedCancellation: {
    ar: 'الفني طلب الإلغاء',
    en: 'Technician requested to cancel',
  },
  other: {
    ar: 'سبب آخر',
    en: 'other',
  },
  requestedByMistake: {
    ar: 'طلب بالخطأ',
    en: 'requested by mistake',
  },
  needNewField: {
    ar: 'احتاج تخصص اخر',
    en: 'Need New Field',
  },
  noAppliedWorker: {
    ar: 'لا يوجد فنى',
    en: 'No Applied Worker',
  },
};

const TRANSACTION_TYPES = {
  custom: {
    ar: 'custom',
    en: 'custom',
  },
  referral: {
    ar: 'referral',
    en: 'referral',
  },
  payment: {
    ar: 'payment',
    en: 'payment',
  },
  commission: {
    ar: 'عمولة',
    en: 'commission',
  },
};

const TRANSACTION_STATUS = {
  approved: {
    ar: 'تمت الموافقة',
    en: 'approved',
  },
  pending: {
    ar: 'معلق',
    en: 'pending',
  },
  rejected: {
    ar: 'مرفوض',
    en: 'rejected',
  },
};

const COMMISION_RATE = {
  0.05: {
    value: 0.05,
  },
  0.06: {
    value: 0.06,
  },
  0.07: {
    value: 0.07,
  },
  0.08: {
    value: 0.08,
  },
  0.09: {
    value: 0.09,
  },
  0.1: {
    value: 0.1,
  },
  0.15: {
    value: 0.15,
  },
  0.2: {
    value: 0.2,
  },
};

const TRANSACTION_SIGN = {
  positive: {
    ar: 'positive',
    en: 'positive',
  },
  negative: {
    ar: 'negative',
    en: 'negative',
  },
};

const REVIEWS_TAGS = {
  delay: {
    ar: 'تأخر',
    en: 'Delay',
  },
  notResponsive: {
    ar: 'غير متعاون',
    en: 'Not responsive',
  },
  talkative: {
    ar: 'كثير الكلام',
    en: 'Talkative',
  },
  slow: {
    ar: 'بطيء',
    en: 'Slow',
  },
  lowBehaviour: {
    ar: 'سيّء التعامل',
    en: 'Low behaviour',
  },
  badhygiene: {
    ar: 'سيّء المظهر',
    en: 'Bad hygiene',
  },
  notCommittedToPrices: {
    ar: 'غالي',
    en: 'Not committed to price',
  },
  quick: {
    ar: 'سريع',
    en: 'Quick',
  },
  responsive: {
    ar: 'متعاون',
    en: 'Responsive',
  },
  goodBehaviour: {
    ar: 'خلوق',
    en: 'Good behaviour',
  },
  goodHygiene: {
    ar: 'أنيق',
    en: 'Good hygiene',
  },
  onTime: {
    ar: 'ملتزم بالوقت',
    en: 'On time',
  },
  helpful: {
    ar: 'فنان',
    en: 'Helpful',
  },
};

const ARRIVAL_ACTIVITY = {
  StartMoving: {
    ar: 'في الطريق',
    en: 'Start moving',
  },
  Arrived: {
    ar: 'وصل',
    en: 'Arrived',
  },
};

const DARK_MODE_MAP_STYLE = [
  {
    featureType: 'all',
    elementType: 'all',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'all',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'on',
      },
      {
        saturation: '-100',
      },
    ],
  },
  {
    featureType: 'all',
    elementType: 'labels.text.fill',
    stylers: [
      {
        saturation: 36,
      },
      {
        color: '#000000',
      },
      {
        lightness: 40,
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'all',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        visibility: 'on',
      },
      {
        color: '#000000',
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    featureType: 'all',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#000000',
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#000000',
      },
      {
        lightness: 17,
      },
      {
        weight: 1.2,
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'geometry',
    stylers: [
      {
        color: '#000000',
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#4d6059',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#4d6059',
      },
    ],
  },
  {
    featureType: 'landscape.natural',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#4d6059',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        lightness: 21,
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#4d6059',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#4d6059',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        visibility: 'on',
      },
      {
        color: '#7f8d89',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#7f8d89',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#7f8d89',
      },
      {
        lightness: 17,
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#7f8d89',
      },
      {
        lightness: 29,
      },
      {
        weight: 0.2,
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry',
    stylers: [
      {
        color: '#000000',
      },
      {
        lightness: 18,
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#7f8d89',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#7f8d89',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'geometry',
    stylers: [
      {
        color: '#000000',
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#7f8d89',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#7f8d89',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [
      {
        color: '#000000',
      },
      {
        lightness: 19,
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'all',
    stylers: [
      {
        color: '#2b3638',
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#2b3638',
      },
      {
        lightness: 17,
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#24282b',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#24282b',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
];

const TIME_UNITES = {
  second: {
    ar: 'ثانية',
    en: 'second',
  },
  minute: {
    ar: 'دقيقة',
    en: 'minute',
  },
  hour: {
    ar: 'ساعة',
    en: 'hour',
  },
};

const GENDER = {
  male: {
    ar: 'ذكر',
    en: 'male',
  },
  female: {
    ar: 'أنثى',
    en: 'female',
  },
};

const ACTIVITIES_LOGS = {
  makeWorkerElite: {
    ar: 'جعل الفنى من النخبة',
    en: 'Make Worker Elite',
  },
  deleteReview: {
    ar: 'حذف تقييم',
    en: 'review deletion',
  },
  approveTransaction: {
    ar: 'قبول عملية',
    en: 'transaction approved',
  },
  rejectTransaction: {
    ar: 'رفض عملية',
    en: 'transaction rejected',
  },
  cancelAllPendingOrders: {
    ar: 'إلغاء جميع الأوامر المعلقة',
    en: 'cancel all pending requests older then requestWaitPeriod',
  },
  cancelOrder: {
    ar: 'الغاء طلب',
    en: 'order canceling',
  },
  hideReview: {
    ar: 'اخفاء التقيم',
    en: 'Hide Review',
  },
  updateCredits: {
    ar: 'إضافة رصيد',
    en: 'credit update',
  },
  suspend: {
    ar: 'ايقاف عن العمل',
    en: 'Suspend',
  },
  unsuspend: {
    ar: 'الغاء ايقاف العمل',
    en: 'Unsuspend',
  },
  unActivateWorker: {
    ar: 'إلغاء تنشيط الفنى',
    en: 'Un Activate Worker',
  },
  activateWorker: {
    ar: 'تنشيط الفنى',
    en: 'Activate Worker',
  },
  updatePassword: {
    ar: 'تغيير كلمة مرور',
    en: 'password update',
  },
  changeWorkerCity: {
    ar: 'تغير مدينة الفنى',
    en: 'Change Worker City',
  },
  signUpWorker: {
    ar: 'تسجيل فنى جديد',
    en: 'Sign Up Worker',
  },
  updateUserName: {
    ar: 'تحديث اسم المستخدم',
    en: 'username update',
  },
  featuring: {
    ar: 'تمييز',
    en: 'feature/de-feature',
  },
  updateField: {
    ar: 'تعديل تخصص',
    en: 'field update',
  },
  updateFields: {
    ar: 'تعديل تخصصات',
    en: 'fields update',
  },
  updateName: {
    ar: 'تعديل اسم',
    en: 'name update',
  },
  customerFollowUps: {
    ar: 'متابعة خدمة العملاء الطلب',
    en: 'Customer Follow Ups',
  },
  operationFollowUps: {
    ar: 'متابعة العمليات الطلب',
    en: 'Operation Follow Ups',
  },
};

const GROUPED_TRANSACTION_TYPES = {
  deposits: {
    message: {
      ar: 'ايداعات',
      en: 'deposits',
    },
    query: { type: 'custom', customTransactionClassificationCode: [101, 102, 103, 105] },
  },
  deductions: {
    message: {
      ar: 'خصومات',
      en: 'deductions',
    },
    query: {
      type: 'custom',
      customTransactionClassificationCode: [200, 201, 202, 203, 204, 205, 206, 207, 208, 209],
    },
  },
  awards: {
    message: {
      ar: 'منحة',
      en: 'awards',
    },
    query: { type: 'custom', customTransactionClassificationCode: [104] },
  },
  payments: {
    message: {
      ar: 'دفع',
      en: 'payments',
    },
    query: { type: 'payment' },
  },
  commissions: {
    message: {
      ar: 'عمولات',
      en: 'commissions',
    },
    query: { type: 'commission' },
  },
  referrals: {
    message: {
      ar: 'إحالات',
      en: 'referrals',
    },
    query: { type: 'referral' },
  },
};

const POSTPONE_REASONS = {
  partsNotAvailableNow: {
    ar: 'العميل طلب التآجيل',
    en: 'customer request to postpone',
  },
  preparePartsForCustomer: {
    ar: 'توفير مواد للعميل',
    en: 'prepare parts for customer',
  },
  customerNotPayThePill: {
    ar: 'العميل لم يدفع الاجرة',
    en: "customer Don't pay the pill",
  },
};

export {
  COOKIES_KEYS,
  USER_ROLES,
  USER_TARGETS,
  OPERATING_SYSTEMS,
  CONFIRM_CHOICE,
  FIELDS,
  CITIES,
  USER_STATUSES,
  REQUESTS_STATUSES,
  TICKETS_STATUS,
  PAYMENT_STATUS,
  PAYMENT_METHOD,
  CANCELLATION_REASONS,
  TRANSACTION_TYPES,
  TRANSACTION_STATUS,
  COMMISION_RATE,
  TRANSACTION_SIGN,
  REVIEWS_TAGS,
  ARRIVAL_ACTIVITY,
  DARK_MODE_MAP_STYLE,
  TIME_UNITES,
  GENDER,
  ACTIVITIES_LOGS,
  GROUPED_TRANSACTION_TYPES,
  POSTPONE_REASONS,
};
