export const CONFIGS = {
  FETCH_CONFIGS: 'FETCH_CONFIGS',
  FETCH_CONFIGS_SUCCESS: 'FETCH_CONFIGS_SUCCESS',
  FETCH_CONFIGS_FAIL: 'FETCH_CONFIGS_FAIL',
};

export const fetchConfigs = () => ({
  type: CONFIGS.FETCH_CONFIGS,
});

export const fetchConfigsSuccess = configs => ({
  type: CONFIGS.FETCH_CONFIGS_SUCCESS,
  configs,
});

export const fetchConfigsFail = err => ({
  type: CONFIGS.FETCH_CONFIGS_FAIL,
  err,
});
