import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { display, space } from 'styled-system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ShimmerEffect, Circle } from 'components/ShimmerEffect';
import { NoAnimationFlex } from 'components/ShimmerEffect/NoAnimationContainer';
import { COLORS, COLORS_VALUES } from 'components/theme/colors';
import { FONT_TYPES } from 'components/theme/fonts';
import Text from 'components/Text';
// import { Caption } from 'components/Text/BackwardTypography';

const { GREY_DARK, GREY_LIGHT, DISABLED } = COLORS;
const { SUBHEADING } = FONT_TYPES;
const activeClassName = 'active';

const MenuItemsContainer = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.greyDark};
  min-height: 100vh;
`;

const SubMenuContainer = styled.ul`
  display: none;
  width: max-content;
  position: absolute;
  ${props => (props.isRtl ? 'right: 100%;' : 'left: 100%;')}
  top: ${props => props.theme.space[1]}px;
  padding: ${props => props.theme.space[1]}px 0;
  list-style: none;
  margin: 0;
  list-style: none;
  background-color: ${COLORS_VALUES[GREY_DARK]};
  min-width: 160px;
  border: 1px solid ${COLORS_VALUES[GREY_LIGHT]}
  border-radius: ${props => props.theme.space[1]}px;
  transition: opacity 0.2s linear;
`;

const SubMenuItem = styled(NavLink).attrs({
  activeClassName,
})`
  &.${activeClassName} {
    opacity: 1;
    color: ${props => props.theme.colors.brandingOrange};
  }
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 30px;
  line-height: 30px;
  color: ${props => props.theme.colors.disabled};
  padding: ${props => props.theme.space[2]}px ${props => props.theme.space[3]}px;
  text-decoration: none;

  &:hover {
    background-color: ${COLORS_VALUES[GREY_LIGHT]};
  }
`;

const MenuItemNoNavLink = styled.nav`
  ${props =>
    props.active
      ? `{
    opacity: 1;

    &::after {
      border-radius: ${props.isRtl ? '4px 0 0 4px' : '0 4px 4px 0'};
      ${props.isRtl ? 'right: 0;' : 'left: 0;'}
      background-color: ${props.theme.colors.disabled};
      content: '';
      display: block;
      height: 80%;
      position: absolute;
      top: 10%;
      width: 4px;
    }
  }`
      : null}
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: 0.6;
  padding: ${props => props.theme.space[4]}px ${props => props.theme.space[1]}px;
  position: relative;
  text-decoration: none;

  &:hover {
    opacity: 1;
  }
`;

const MenuItem = styled(NavLink).attrs({
  activeClassName,
})`
  &.${activeClassName} {
    opacity: 1;

    &::after {
      border-radius: ${props => (props.isrtl === 'true' ? '4px 0 0 4px' : '0 4px 4px 0')};
      background-color: ${props => props.theme.colors.disabled};
      content: '';
      display: block;
      height: 80%;
      ${props => (props.isrtl === 'true' ? 'right: 0;' : 'left: 0;')}
      position: absolute;
      top: 10%;
      width: 4px;
    }
  }
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: 0.6;
  padding: ${props => props.theme.space[4]}px ${props => props.theme.space[1]}px;
  position: relative;
  text-decoration: none;

  &:hover {
    opacity: 1;
  }
`;

const MenuItemName = styled(Text)`
  ${display};
  ${space};
  width: 100%;
  cursor: pointer;
  line-height: 12px;
  text-align: ${props => (props.isrtl === 'true' ? 'right' : 'left')};
`;

// const TabName = styled(Caption)`
//   ${display};
//   color: ${props => props.theme.colors.disabled};
//   cursor: pointer;
//   line-height: 12px;
//   margin-top: ${props => props.theme.space[2]}px;
//   text-align: ${props => (props.isrtl === 'true' ? 'right' : 'left')};
// `;

class Tabs extends Component {
  getLoadingTab = index => (
    <NoAnimationFlex
      justifyContent="center"
      alignItems="center"
      className="tab tab--loading"
      key={index}
    >
      <Circle radius={18} />
    </NoAnimationFlex>
  );

  showSubmenu = index => {
    this[`submenu${index}`].style.display = 'block';
  };

  hideSubmenu = index => {
    this[`submenu${index}`].style.display = 'none';
  };

  renderTabs = (navs, isRtl) => {
    const { activeNav } = this.props;

    return navs.map((nav, index) => {
      if (nav.enable) {
        if (nav.type === 'nav') {
          return (
            <MenuItem
              key={`${nav.name.en}-${nav.name.en}`}
              isrtl={isRtl ? 'true' : 'false'}
              data-for={`nav_${nav.name.ar}`}
              data-tip
              activeClassName={
                activeNav === nav.url || nav.url.includes(activeNav) ? 'active' : undefined
              }
              to={nav.url}
              href={nav.url}
            >
              {nav.icon && (
                <FontAwesomeIcon icon={nav.icon} color={COLORS_VALUES[DISABLED]} size="lg" />
              )}
              {nav.name && (
                <ReactTooltip id={`nav_${nav.name.ar}`} place="left" type="light" effect="solid">
                  <span>{nav.name.ar}</span>
                </ReactTooltip>
                /* <TabName
                  isrtl={isRtl ? 'true' : 'false'}
                  display={['none', 'block', 'block', 'block', 'block']}
                >
                  {nav.name.ar}
                </TabName> */
              )}
            </MenuItem>
          );
        }

        return (
          <MenuItemNoNavLink
            isRtl={isRtl}
            active={activeNav === nav.url || activeNav.includes(nav.url)}
            key={`${nav.name.en}-${nav.name.en}`}
            onMouseEnter={() => this.showSubmenu(index)}
            onMouseLeave={() => this.hideSubmenu(index)}
          >
            {nav.icon && (
              <FontAwesomeIcon icon={nav.icon} color={COLORS_VALUES[DISABLED]} size="lg" />
            )}
            {/* nav.name && (
              <TabName
                isrtl={isRtl ? 'true' : 'false'}
                display={['none', 'block', 'block', 'block', 'block']}
              >
                {nav.name.ar}
              </TabName>
            ) */}
            <SubMenuContainer
              isRtl={isRtl}
              ref={submenu => {
                this[`submenu${index}`] = submenu;
              }}
            >
              {nav.submenuItems.map(submenuItem => {
                if (submenuItem.enable) {
                  return (
                    <SubMenuItem
                      key={`${submenuItem.name.en}-${submenuItem.name.en}`}
                      to={submenuItem.url}
                      href={submenuItem.url}
                      onClick={() => this.hideSubmenu(index)}
                    >
                      {submenuItem.name && (
                        <MenuItemName
                          isrtl={isRtl ? 'true' : 'false'}
                          ml={1}
                          mr={2}
                          type={SUBHEADING}
                        >
                          {submenuItem.name.ar}
                        </MenuItemName>
                      )}
                      {submenuItem.icon && (
                        <FontAwesomeIcon
                          icon={submenuItem.icon}
                          color={COLORS_VALUES[DISABLED]}
                          size="sm"
                        />
                      )}
                    </SubMenuItem>
                  );
                }
                return null;
              })}
            </SubMenuContainer>
          </MenuItemNoNavLink>
        );
      }

      return null;
    });
  };

  renderLazyLoadingNavs = () => {
    const loadingNavs = [];
    for (let counter = 0; counter < 7; counter += 1) {
      loadingNavs.push(this.getLoadingTab(counter));
    }

    return <ShimmerEffect flexDirection="column">{loadingNavs}</ShimmerEffect>;
  };

  render() {
    const { isLoading, navs } = this.props;

    return (
      <MenuItemsContainer>
        {isLoading ? this.renderLazyLoadingTabs() : this.renderTabs(navs, true)}
      </MenuItemsContainer>
    );
  }
}

Tabs.propTypes = {
  navs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      icon: PropTypes.object,
      url: PropTypes.string,
      routeNames: PropTypes.array,
      enable: PropTypes.bool,
    }),
  ).isRequired,
  activeNav: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default Tabs;
