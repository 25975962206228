export const VENDORS = {
  GET_VENDOR_DETAILS: 'GET_VENDOR_DETAILS',
  GET_VENDOR_DETAILS_SUCCESS: 'GET_VENDOR_DETAILS_SUCCESS',
  GET_VENDOR_DETAILS_FAIL: 'GET_VENDOR_DETAILS_FAIL',
};

export const getVendorDetails = vendorId => ({
  type: VENDORS.GET_VENDOR_DETAILS,
  vendorId,
});

export const getAllWorkersSuccess = vendorDetails => ({
  type: VENDORS.GET_VENDOR_DETAILS_SUCCESS,
  vendorDetails,
});

export const getAllWorkersFail = err => ({
  type: VENDORS.GET_VENDOR_DETAILS_FAIL,
  err,
});
