import { VENDORS } from './actions';

const initialState = {
  vendorDetails: undefined,
  getVendorDetails: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: '' },
  },
};
export default (state = initialState, { type, ...payload }) => {
  const { GET_VENDOR_DETAILS, GET_VENDOR_DETAILS_SUCCESS, GET_VENDOR_DETAILS_FAIL } = VENDORS;
  const { vendorDetails, err } = payload;

  switch (type) {
    case GET_VENDOR_DETAILS: {
      return {
        ...state,
        getVendorDetails: {
          isFetching: true,
          isSuccess: false,
          isFail: { isError: false, message: '' },
        },
      };
    }
    case GET_VENDOR_DETAILS_SUCCESS: {
      console.log('vendorDetails', vendorDetails);
      return {
        ...state,
        vendorDetails,
        getVendorDetails: {
          isFetching: false,
          isSuccess: true,
          isFail: { isError: false, message: '' },
        },
      };
    }
    case GET_VENDOR_DETAILS_FAIL: {
      return {
        ...state,
        getAllWorkers: {
          isFetching: false,
          isSuccess: false,
          isFail: { isError: true, message: err },
        },
      };
    }
    default:
      return state;
  }
};
