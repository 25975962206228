import { CONFIGS } from './actions';

const initialState = {
  configs: undefined,
  fetchConfigs: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: '' },
  },
};

export default (state = initialState, { type, ...payload }) => {
  const { FETCH_CONFIGS, FETCH_CONFIGS_SUCCESS, FETCH_CONFIGS_FAIL } = CONFIGS;
  const { configs, err } = payload;

  switch (type) {
    case FETCH_CONFIGS: {
      return {
        ...state,
        fetchConfigs: {
          isFetching: true,
          isSuccess: false,
          isFail: { isError: false, message: '' },
        },
      };
    }
    case FETCH_CONFIGS_SUCCESS: {
      return {
        ...state,
        configs,
        fetchConfigs: {
          isFetching: false,
          isSuccess: true,
          isFail: { isError: false, message: '' },
        },
      };
    }
    case FETCH_CONFIGS_FAIL: {
      return {
        ...state,
        fetchConfigs: {
          isFetching: false,
          isSuccess: false,
          isFail: { isError: true, message: err },
        },
      };
    }
    default:
      return state;
  }
};
