export const REVIEWS = {
  GET_ALL_REVIEWS: 'GET_ALL_REVIEWS',
  GET_ALL_REVIEWS_SUCCESS: 'GET_ALL_REVIEWS_SUCCESS',
  GET_ALL_REVIEWS_FAIL: 'GET_ALL_REVIEWS_FAIL',
};

export const getAllReviews = (options, applyNewFilter: false) => ({
  type: REVIEWS.GET_ALL_REVIEWS,
  options,
  applyNewFilter,
});

export const getAllReviewsSuccess = (reviews, applyNewFilter: false) => ({
  type: REVIEWS.GET_ALL_REVIEWS_SUCCESS,
  reviews,
  applyNewFilter,
});

export const getAllReviewsFail = err => ({
  type: REVIEWS.GET_ALL_REVIEWS_FAIL,
  err,
});
