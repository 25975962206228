import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Loadable from 'react-loadable';
import Cookies from 'js-cookie';
import { createGlobalStyle } from 'styled-components';
import ROUTES from 'routes';
import { fetchTransactionsClassification } from 'redux-modules/statics/actions';
import { fetchConfigs } from 'redux-modules/configs/actions';
import { loginSuccess } from 'redux-modules/user/actions';
import { USER_ROLES, COOKIES_KEYS } from 'utils/constants';
import { decodeJwtToken } from 'utils/shared/encryption';
import Layout from 'components/Layout';
import Splash from 'components/Splash';
import Loading from 'components/Loading';
import Logo from 'static/images/logo.png';
import englishFont from 'static/fonts/EnglishFont.ttf';
import arabicFont from 'static/fonts/DubaiW23-Regular.ttf';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: english-font;
    src: url(${englishFont});
  }
  @font-face {
    font-family: arabic-font;
    src: url(${arabicFont});
  }

  input, textarea, select, button {
    font-family: 'english-font';

    :lang(ar) {
      font-family: 'arabic-font';
    }
  }

  body {
    font-family: 'english-font';
    background-color: ${props => props.theme.colors.greyDark};
    margin: 0;

    :lang(ar) {
      font-family: 'arabic-font';
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid ${props => props.theme.colors.greyDark};
    -webkit-text-fill-color: ${props => props.theme.colors.brandingOrange};
    -webkit-box-shadow: 0 0 0px 1000px ${props => props.theme.colors.greyLight} inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  textarea {
    resize: none;
  }
`;

const LoginContainer = Loadable({
  loader: () => import('./Login'),
  loading: Loading,
});

const DashboardContainer = Loadable({
  loader: () => import('./Dashboard'),
  loading: Loading,
});

const RequestsContainer = Loadable({
  loader: () => import('./Requests'),
  loading: Loading,
});

const TicketsContainer = Loadable({
  loader: () => import('./Tickets'),
  loading: Loading,
});

const WorkersContainer = Loadable({
  loader: () => import('./Workers'),
  loading: Loading,
});

const SingleWorkerContainer = Loadable({
  loader: () => import('./SingleWorker'),
  loading: Loading,
});

const SingleRequestContainer = Loadable({
  loader: () => import('./SingleRequest'),
  loading: Loading,
});

const SingleTicketContainer = Loadable({
  loader: () => import('./SingleTicket'),
  loading: Loading,
});

const RequestsPaymentsContainer = Loadable({
  loader: () => import('./Reports/RequestsPayments'),
  loading: Loading,
});

const WorkersRequestsCountContainer = Loadable({
  loader: () => import('./Reports/WorkersRrquestsCount'),
  loading: Loading,
});

const WorkersTransactionsContainer = Loadable({
  loader: () => import('./Reports/WorkersTransactions'),
  loading: Loading,
});


const firebaseConfig = {
  apiKey: "AIzaSyCr6qJuyOgqLtNjcbVNV09x7eMdgYPCCZI",
  authDomain: "fanniapp-bdcf4.firebaseapp.com",
  databaseURL: "https://fanniapp-bdcf4.firebaseio.com",
  projectId: "fanniapp-bdcf4",
  storageBucket: "fanniapp-bdcf4.appspot.com",
  messagingSenderId: "591978559350",
  appId: "1:591978559350:web:33506b1d0b38c5d91c6c57",
  measurementId: "G-J7RZEXJ2S9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

class App extends Component {
  static propTypes = {
    history: PropTypes.shape({}).isRequired,
    user: PropTypes.shape({}),
    isGetTransactionsClassificationSuccess: PropTypes.bool,
    fetchTransactionsClassification: PropTypes.func,
    isFetchConfigSuccess: PropTypes.bool,
    fetchConfigs: PropTypes.func,
    loginSuccess: PropTypes.func,
  };

  static defaultProps = {
    user: undefined,
    isGetTransactionsClassificationSuccess: false,
    fetchTransactionsClassification: () => {},
    isFetchConfigSuccess: false,
    fetchConfigs: () => {},
    loginSuccess: () => {},
  };

  state = {
    isLoading: true,
  };

  componentDidMount() {
    const {
      history,
      isGetTransactionsClassificationSuccess,
      fetchTransactionsClassification: fetchTransactionsClassificationAction,
      isFetchConfigSuccess,
      fetchConfigs: fetchConfigsAction,
      loginSuccess: loginSuccessAction,
    } = this.props;
    const { TOKEN, PLAIN_USER } = COOKIES_KEYS;
    const { VENDOR } = USER_ROLES;
    const { LOGIN, DASHBOARD } = ROUTES;
    const isUserLoggedIn = Cookies.get(TOKEN) && Cookies.get(PLAIN_USER);
    const token = Cookies.get(TOKEN);
    const plainUser = Cookies.get(PLAIN_USER);
    const {
      location: { pathname },
    } = window;

    if (isUserLoggedIn) {
      const {
        payLoad: { role },
      } = decodeJwtToken(token);
      const user = JSON.parse(plainUser);

      loginSuccessAction(user);

      if (!isGetTransactionsClassificationSuccess) {
        fetchTransactionsClassificationAction();
      }

      if (!isFetchConfigSuccess) {
        fetchConfigsAction();
      }

      if (role !== VENDOR) {
        Cookies.remove(TOKEN);

        if (pathname !== LOGIN) {
          history.push(LOGIN);
        }

        setTimeout(() => {
          this.setState({ isLoading: false });
        }, 1500);
      } else {
        if (pathname === LOGIN) {
          history.push(DASHBOARD);
        }

        setTimeout(() => {
          this.setState({ isLoading: false });
        }, 1500);
      }
    } else if (pathname !== LOGIN) {
      history.push(LOGIN);

      setTimeout(() => {
        this.setState({ isLoading: false });
      }, 1500);
    } else {
      setTimeout(() => {
        this.setState({ isLoading: false });
      }, 1500);
    }
  }

  logout = () => {
    const { history } = this.props;
    const { TOKEN, PLAIN_USER } = COOKIES_KEYS;
    const { LOGIN } = ROUTES;

    Cookies.remove(TOKEN);
    Cookies.remove(PLAIN_USER);
    history.push(LOGIN);
  };

  

  render() {
    const { history, user } = this.props;
    const { isLoading } = this.state;
    const {
      DASHBOARD,
      REQUESTS,
      TICKETS,
      LOGIN,
      WORKERS,
      SINGLE_WORKER,
      SINGLE_REQUEST,
      SINGLE_TICKET,
      REPORT_REQUESTS_PAYMENTS,
      REPORT_WORKERS_REQUESTS_COUNT,
      REPORT_WORKERS_TRANSACTIONS,
    } = ROUTES;

    let content;

    if (isLoading) {
      content = <Splash logoUrl={Logo} />;
    } else {
      content = (
        <Switch>
          <Route exact path={LOGIN} component={LoginContainer} />
          <Layout logout={this.logout} history={history} user={user}>
            <Switch>
              <Route exact path={DASHBOARD} component={DashboardContainer} />
              <Route exact path={REQUESTS} component={RequestsContainer} />
              <Route exact path={TICKETS} component={TicketsContainer} />
              <Route exact path={WORKERS} component={WorkersContainer} />
              <Route exact path={`${SINGLE_WORKER}/:id`} component={SingleWorkerContainer} />
              <Route exact path={`${SINGLE_REQUEST}/:id`} component={SingleRequestContainer} />
              <Route exact path={`${SINGLE_TICKET}/:id`} component={SingleTicketContainer} />
              <Route exact path={REPORT_REQUESTS_PAYMENTS} component={RequestsPaymentsContainer} />
              <Route
                exact
                path={REPORT_WORKERS_REQUESTS_COUNT}
                component={WorkersRequestsCountContainer}
              />
              <Route
                exact
                path={REPORT_WORKERS_TRANSACTIONS}
                component={WorkersTransactionsContainer}
              />
              <Redirect to={DASHBOARD} />
            </Switch>
          </Layout>
        </Switch>
      );
    }
    return (
      <>
        <GlobalStyle />
        {content}
      </>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.user,
  isFetchConfigSuccess: state.configs.fetchConfigs.isSuccess,
  isGetTransactionsClassificationSuccess: state.statics.getTransactionsClassification.isSuccess,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ fetchTransactionsClassification, fetchConfigs, loginSuccess }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);
