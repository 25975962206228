import { takeEvery, put } from 'redux-saga/effects';
import Cookies from 'js-cookie';
import ROUTES from 'routes/index';
import { Api, Urls, Status } from 'utils/api';
import { COOKIES_KEYS } from 'utils/constants';
import { USER } from './actions';

const {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  EXPORT_FILE,
  EXPORT_FILE_SUCCESS,
  EXPORT_FILE_FAIL,
} = USER;

function* login(payload) {
  const { DASHBOARD } = ROUTES;
  const {
    userCredentials: { userName, password },
    historyPush,
  } = payload;
  const { user } = Urls;
  const api = new Api();
  const url = user.login;
  const body = {
    username: userName,
    password,
  };
  const response = yield api.post(url, body);

  if (Status.isSuccess(response.status)) {
    const {
      response: { plainUser, token },
    } = response;
    const { TOKEN, PLAIN_USER } = COOKIES_KEYS;

    Cookies.set(TOKEN, token, { expires: 100 });
    Cookies.set(PLAIN_USER, plainUser, { expires: 100 });

    yield put({
      type: LOGIN_SUCCESS,
      user: plainUser,
    });

    yield historyPush(DASHBOARD);
  } else {
    const {
      response: { message },
    } = response;

    yield put({
      type: LOGIN_FAIL,
      err: message,
    });
  }
}

function* exportFile(payload) {
  const { TOKEN } = COOKIES_KEYS;
  const token = Cookies.get(TOKEN);
  const { exportUrl } = payload;
  const api = new Api();
  const header = [
    {
      key: 'token',
      value: token,
    },
  ];
  const response = yield api.get(exportUrl, header);

  if (Status.isSuccess(response.status)) {
    // download .csv file
    const downloadLink = document.createElement('a');
    const blob = new Blob(['\ufeff', response.response], {
      type: 'text/csv',
    });
    const url = URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.download = 'data.csv';

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    yield put({
      type: EXPORT_FILE_SUCCESS,
    });
  } else {
    const {
      response: { message },
    } = response;

    yield put({
      type: EXPORT_FILE_FAIL,
      err: message,
    });
  }
}

function* userSaga() {
  yield takeEvery(LOGIN, login);
  yield takeEvery(EXPORT_FILE, exportFile);
}

export default userSaga;
