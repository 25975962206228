export const REQUESTS = {
  GET_ALL_REQUESTS: 'GET_ALL_REQUESTS',
  GET_ALL_REQUESTS_SUCCESS: 'GET_ALL_REQUESTS_SUCCESS',
  GET_ALL_REQUESTS_FAIL: 'GET_ALL_REQUESTS_FAIL',
  GET_REQUEST_DETAILS_BY_PRETTY_ID: 'GET_REQUEST_DETAILS_BY_PRETTY_ID',
  GET_REQUEST_DETAILS_BY_PRETTY_ID_SUCCESS: 'GET_REQUEST_DETAILS_BY_PRETTY_ID_SUCCESS',
  GET_REQUEST_DETAILS_BY_PRETTY_ID_FAIL: 'GET_REQUEST_DETAILS_BY_PRETTY_ID_FAIL',
  GET_REQUESTS_PAYMENTS: 'GET_REQUESTS_PAYMENTS',
  GET_REQUESTS_PAYMENTS_SUCCESS: 'GET_REQUESTS_PAYMENTS_SUCCESS',
  GET_REQUESTS_PAYMENTS_FAIL: 'GET_REQUESTS_PAYMENTS_FAIL',
  CONNECT_TO_SOCKET_IO: 'CONNECT_TO_SOCKET_IO',
  NEW_REQUEST_CREATED: 'NEW_REQUEST_CREATED',
  SOCKET_IO_SERVER_OFF: 'SOCKET_IO_SERVER_OFF',
  SOCKET_IO_SERVER_ON: 'SOCKET_IO_SERVER_ON',
  REQUEST_UPDATED: 'REQUEST_UPDATED',
  GET_RECONDITIONING_REQUESTS_COUNT: 'GET_RECONDITIONING_REQUESTS_COUNT',
  GET_RECONDITIONING_REQUESTS_COUNT_SUCCESS: 'GET_RECONDITIONING_REQUESTS_COUNT_SUCCESS',
  GET_RECONDITIONING_REQUESTS_COUNT_FAIL: 'GET_RECONDITIONING_REQUESTS_COUNT_FAIL',
};

export const getAllRequests = (options, applyNewFilter: false) => ({
  type: REQUESTS.GET_ALL_REQUESTS,
  options,
  applyNewFilter,
});

export const getAllRequestsSuccess = (requests, applyNewFilter: false) => ({
  type: REQUESTS.GET_ALL_REQUESTS_SUCCESS,
  requests,
  applyNewFilter,
});

export const getAllRequestsFail = err => ({
  type: REQUESTS.GET_ALL_REQUESTS_FAIL,
  err,
});

export const getRequestDetailsByPrettyId = requestPrettyId => ({
  type: REQUESTS.GET_REQUEST_DETAILS_BY_PRETTY_ID,
  requestPrettyId,
});

export const getRequestDetailsByPrettyIdSuccess = requestDetails => ({
  type: REQUESTS.GET_REQUEST_DETAILS_BY_PRETTY_ID_SUCCESS,
  requestDetails,
});

export const getRequestDetailsByPrettyIdFail = err => ({
  type: REQUESTS.GET_REQUEST_DETAILS_BY_PRETTY_ID_FAIL,
  err,
});

export const getRequestsPayments = (options, applyNewFilter: false) => ({
  type: REQUESTS.GET_REQUESTS_PAYMENTS,
  options,
  applyNewFilter,
});

export const getRequestsPaymentsSuccess = (requestsPayments, applyNewFilter: false) => ({
  type: REQUESTS.GET_REQUESTS_PAYMENTS_SUCCESS,
  requestsPayments,
  applyNewFilter,
});

export const getRequestsPaymentsFail = err => ({
  type: REQUESTS.GET_REQUESTS_PAYMENTS_FAIL,
  err,
});

export const connectToSocketIo = () => ({
  type: REQUESTS.CONNECT_TO_SOCKET_IO,
});

export const socketIoDidnotConnectToServer = () => ({
  type: REQUESTS.SOCKET_IO_SERVER_OFF,
});

export const socketIoConnectedToServer = () => ({
  type: REQUESTS.SOCKET_IO_SERVER_ON,
});

export const newRequestCreated = newRequest => ({
  type: REQUESTS.NEW_REQUEST_CREATED,
  newRequest,
});

export const updateRequest = updatedRequest => ({
  type: REQUESTS.REQUEST_UPDATED,
  updatedRequest,
});

export const getReconditioningRequestsCount = options => ({
  type: REQUESTS.GET_RECONDITIONING_REQUESTS_COUNT,
  options,
});

export const getReconditioningRequestsCountSuccess = reconditioningRequestsCount => ({
  type: REQUESTS.GET_RECONDITIONING_REQUESTS_COUNT_SUCCESS,
  reconditioningRequestsCount,
});

export const getReconditioningRequestsCountFail = err => ({
  type: REQUESTS.GET_RECONDITIONING_REQUESTS_COUNT_FAIL,
  err,
});
