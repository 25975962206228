import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { width, display } from 'styled-system';
import {
  faExchangeAlt,
  faTicketAlt,
  faWrench,
  faFileAlt,
  faChartBar,
} from '@fortawesome/free-solid-svg-icons';
import ROUTES from 'routes';
import Items from './Items';

const {
  DASHBOARD,
  REQUESTS,
  TICKETS,
  WORKERS,
  REPORTS,
  REPORT_REQUESTS_PAYMENTS,
  REPORT_WORKERS_REQUESTS_COUNT,
  REPORT_WORKERS_TRANSACTIONS,
} = ROUTES;

const SideMenuContainer = styled.div`
  ${display};
  ${width};
  ${props => (props.isRtl ? 'right: 0;' : 'left: 0;')}
  background-color: ${props => props.theme.colors.greyDark};
  border-right: 1px solid ${props => props.theme.colors.greyLight};
  justify-content: space-around;
  padding: 0;
  position: fixed;
  top: 66px;
  z-index: 2;
`;

const VerticalHeader = props => {
  const {
    location: { pathname },
  } = props;
  const activeNav = pathname;
  const navs = [
    {
      type: 'nav',
      name: {
        ar: 'الرئيسية',
        en: 'Dashboard',
      },
      icon: faChartBar,
      url: DASHBOARD,
      enable: true,
    },
    {
      type: 'nav',
      name: {
        ar: 'الطلبات',
        en: 'Requests',
      },
      icon: faExchangeAlt,
      url: REQUESTS,
      enable: true,
    },
    {
      type: 'nav',
      name: {
        ar: 'الشكاوي',
        en: 'Tickets',
      },
      icon: faTicketAlt,
      url: TICKETS,
      enable: true,
    },
    {
      type: 'nav',
      name: {
        ar: 'الفنيين',
        en: 'Map',
      },
      icon: faWrench,
      url: WORKERS,
      enable: true,
    },
    {
      type: 'submenu',
      name: {
        ar: 'التقارير',
        en: 'Reports',
      },
      icon: faFileAlt,
      url: REPORTS,
      enable: true,
      submenuItems: [
        {
          type: 'nav',
          name: {
            ar: 'تقرير تكلفة الطلبات',
            en: 'Requests Payment',
          },
          icon: faFileAlt,
          url: REPORT_REQUESTS_PAYMENTS,
          enable: true,
        },
        {
          type: 'nav',
          name: {
            ar: 'تقرير عدد الطلبات والدفع للفنيين',
            en: 'Workers Requests Count',
          },
          icon: faFileAlt,
          url: REPORT_WORKERS_REQUESTS_COUNT,
          enable: true,
        },
        {
          type: 'nav',
          name: {
            ar: 'تقرير العمليات المالية لجميع الفنيين',
            en: 'Workers Transactions',
          },
          icon: faFileAlt,
          url: REPORT_WORKERS_TRANSACTIONS,
          enable: true,
        },
      ],
    },
  ];

  return (
    <SideMenuContainer isRtl width={['35px', '75px', '75px', '75px', '75px']}>
      <Items isLoading={false} activeNav={activeNav} navs={navs} />
    </SideMenuContainer>
  );
};

VerticalHeader.propTypes = {
  user: PropTypes.shape({}),
  location: PropTypes.shape({
    hash: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
};

VerticalHeader.defaultProps = {
  user: undefined,
};

export default VerticalHeader;
