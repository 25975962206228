export const USER = {
  LOGIN: 'LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAIL: 'LOGIN_FAIL',
  EXPORT_FILE: 'EXPORT_FILE',
  EXPORT_FILE_SUCCESS: 'EXPORT_FILE_SUCCESS',
  EXPORT_FILE_FAIL: 'EXPORT_FILE_FAIL',
};

export const login = (userCredentials, historyPush) => ({
  type: USER.LOGIN,
  userCredentials,
  historyPush,
});

export const loginSuccess = user => ({
  type: USER.LOGIN_SUCCESS,
  user,
});

export const loginFail = err => ({
  type: USER.LOGIN_FAIL,
  err,
});

export const exportFile = exportUrl => ({
  type: USER.EXPORT_FILE,
  exportUrl,
});

export const exportFileSuccess = () => ({
  type: USER.EXPORT_FILE_SUCCESS,
});

export const exportFileFail = err => ({
  type: USER.EXPORT_FILE_FAIL,
  err,
});

export const toggleAddEditAdminModal = () => ({
  type: USER.TOGGLE_ADD_EDIT_ADMIN_MODAL,
});

export const toggleChangeAdminPasswordModal = () => ({
  type: USER.TOGGLE_CHANGE_ADMIN_PASSWORD_MODAL,
});
