import { takeEvery, takeLatest, put } from 'redux-saga/effects';
import Cookies from 'js-cookie';
import { Api, Urls, Status } from 'utils/api';
import { COOKIES_KEYS } from 'utils/constants';
import { TICKETS } from './actions';

const {
  GET_ALL_TICKETS,
  GET_ALL_TICKETS_SUCCESS,
  GET_ALL_TICKETS_FAIL,
  GET_TICKET_DETAILS,
  GET_TICKET_DETAILS_SUCCESS,
  GET_TICKET_DETAILS_FAIL,
  GET_TICKETS_COUNT_IN_EACH_STATUS,
} = TICKETS;

function* getAllTickets(payload) {
  const { options, applyNewFilter } = payload;
  const { TOKEN } = COOKIES_KEYS;
  const token = Cookies.get(TOKEN);
  const { tickets } = Urls;
  const api = new Api();
  const header = [
    {
      key: 'token',
      value: token,
    },
  ];
  let url = tickets.general;

  Object.keys(options).forEach((key, index) => {
    if (index === 0) {
      url = `${url}?${key}=${options[key]}`;
    } else {
      url = `${url}&${key}=${options[key]}`;
    }
  });

  const response = yield api.get(url, header);

  if (Status.isSuccess(response.status)) {
    const { response: allWorkers } = response;

    yield put({
      type: GET_ALL_TICKETS_SUCCESS,
      tickets: allWorkers,
      applyNewFilter,
    });
  } else {
    const {
      response: { message },
    } = response;

    yield put({
      type: GET_ALL_TICKETS_FAIL,
      err: message,
    });
  }
}

function* getTicketDetails(payload) {
  const { ticketId } = payload;
  const { TOKEN } = COOKIES_KEYS;
  const token = Cookies.get(TOKEN);
  const { tickets } = Urls;
  const url = `${tickets.general}/${ticketId}`;
  const api = new Api();
  const header = [
    {
      key: 'token',
      value: token,
    },
  ];
  const response = yield api.get(url, header);

  if (Status.isSuccess(response.status)) {
    const ticketDetails = response.response;

    yield put({
      type: GET_TICKET_DETAILS_SUCCESS,
      ticketDetails,
    });
  } else {
    const {
      response: { message },
    } = response;

    yield put({
      type: GET_TICKET_DETAILS_FAIL,
      err: message,
    });
  }
}

function* getTicketsCountInEachStatus(payload) {
  const { options } = payload;
  const { TOKEN } = COOKIES_KEYS;
  const token = Cookies.get(TOKEN);
  const { tickets } = Urls;
  const api = new Api();
  let url = tickets.ticketsCountInEachStatus;
  const header = [
    {
      key: 'token',
      value: token,
    },
  ];
  Object.keys(options).forEach((key, index) => {
    if (index === 0) {
      url = `${url}?${key}=${options[key]}`;
    } else {
      url = `${url}&${key}=${options[key]}`;
    }
  });
  const response = yield api.get(url, header);
  const responseStatus = response.status;
  const ticketsCountInEachStatus = response.response;

  if (Status.isSuccess(responseStatus)) {
    yield put({
      type: GET_TICKETS_COUNT_IN_EACH_STATUS.SUCCESS,
      ticketsCountInEachStatus,
    });
  } else {
    const {
      response: { message },
    } = response;

    yield put({
      type: GET_TICKETS_COUNT_IN_EACH_STATUS.FAIL,
      err: message,
    });
  }
}

function* ticketsSaga() {
  yield takeLatest(GET_ALL_TICKETS, getAllTickets);
  yield takeEvery(GET_TICKET_DETAILS, getTicketDetails);
  yield takeEvery(GET_TICKETS_COUNT_IN_EACH_STATUS.LOAD, getTicketsCountInEachStatus);
}

export default ticketsSaga;
