import { STATICS } from './actions';

const initialState = {
  transactionsClassification: undefined,
  followUpsList: undefined,
  getTransactionsClassification: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: '' },
  },
  getFollowUpsList: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: '' },
  },
};

export default (state = initialState, { type, ...payload }) => {
  const {
    FETCH_TRANSACTIONS_CLASSIFICATION,
    FETCH_TRANSACTIONS_CLASSIFICATION_SUCCESS,
    FETCH_TRANSACTIONS_CLASSIFICATION_FAIL,
    FETCH_FOLLOW_UPS_LIST,
    FETCH_FOLLOWUPS_LIST_SUCCESS,
    FETCH_FOLLOWUPS_LIST_FAIL,
  } = STATICS;
  const { transactionsClassification, followUpsList, error } = payload;

  switch (type) {
    case FETCH_TRANSACTIONS_CLASSIFICATION: {
      return {
        ...state,
        getTransactionsClassification: {
          isFetching: true,
          isSuccess: false,
          isFail: { isError: false, message: '' },
        },
      };
    }
    case FETCH_TRANSACTIONS_CLASSIFICATION_SUCCESS: {
      return {
        ...state,
        transactionsClassification,
        getTransactionsClassification: {
          isFetching: false,
          isSuccess: true,
          isFail: { isError: false, message: '' },
        },
      };
    }
    case FETCH_TRANSACTIONS_CLASSIFICATION_FAIL: {
      return {
        ...state,
        getTransactionsClassification: {
          isFetching: false,
          isSuccess: false,
          isFail: { isError: true, message: error },
        },
      };
    }
    case FETCH_FOLLOW_UPS_LIST: {
      return {
        ...state,
        getFollowUpsList: {
          isFetching: true,
          isSuccess: false,
          isFail: { isError: false, message: '' },
        },
      };
    }
    case FETCH_FOLLOWUPS_LIST_SUCCESS: {
      return {
        ...state,
        followUpsList,
        getFollowUpsList: {
          isFetching: false,
          isSuccess: true,
          isFail: { isError: false, message: '' },
        },
      };
    }
    case FETCH_FOLLOWUPS_LIST_FAIL: {
      return {
        ...state,
        getFollowUpsList: {
          isFetching: false,
          isSuccess: false,
          isFail: { isError: true, message: error },
        },
      };
    }
    default:
      return state;
  }
};
