import { unionBy } from 'lodash';
import update from 'immutability-helper';
import { WORKERS } from './actions';

const initialState = {
  workers: undefined,
  hasMoreWorkers: true,
  applyNewFilter: false,
  workerDetails: undefined,
  workersRequestsCount: undefined,
  hasMoreWorkersRequestsCount: true,
  getAllWorkers: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: '' },
  },
  addWorker: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: '' },
  },
  editWorker: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: '' },
  },
  getWorkerDetails: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: '' },
  },
  getWorkersRequestsCount: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: '' },
  },
  opsCommentsModal: { isOpen: false },
  confirmModal: { isOpen: false },
  addNewWorkerModal: { isOpen: false },
  editWorkerModal: { isOpen: false },
  getWorkerStatistics: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: '' },
  },
  workerStatistics: undefined,
  getWorkerMotivationAndPunishments: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: '' },
  },
  workerMotivationAndPunishments: undefined,
  changeWorkerPassword: {
    isFetching: false,
    isSuccess: false,
    isFail: { isError: false, message: '' },
  },
  changeWorkerPasswordModal: { isOpen: false },
};
export default (state = initialState, { type, ...payload }) => {
  const {
    GET_ALL_WORKERS,
    GET_ALL_WORKERS_SUCCESS,
    GET_ALL_WORKERS_FAIL,
    ADD_WORKER,
    ADD_WORKER_SUCCESS,
    ADD_WORKER_FAIL,
    ADD_WORKER_RESET,
    EDIT_WORKER,
    EDIT_WORKER_SUCCESS,
    EDIT_WORKER_FAIL,
    EDIT_WORKER_RESET,
    GET_WORKER_DETAILS,
    GET_WORKER_DETAILS_SUCCESS,
    GET_WORKER_DETAILS_FAIL,
    TOGGLE_ADD_NEW_WORKER_MODAL,
    GET_WORKERS_REQUESTS_COUNT,
    GET_WORKERS_REQUESTS_COUNT_SUCCESS,
    GET_WORKERS_REQUESTS_COUNT_FAIL,
    GET_WORKER_STATISTICS,
    GET_WORKER_STATISTICS_SUCCESS,
    GET_WORKER_STATISTICS_FAIL,
    GET_WORKER_MOTIVATIONS_AND_PUNISHMENTS,
    GET_WORKER_MOTIVATIONS_AND_PUNISHMENTS_SUCCESS,
    GET_WORKER_MOTIVATIONS_AND_PUNISHMENTS_FAIL,
    TOGGLE_CHANGE_WORKER_PASSWORD_MODAL,
    CHANGE_WORKER_PASSWORD,
    CHANGE_WORKER_PASSWORD_SUCCESS,
    CHANGE_WORKER_PASSWORD_FAIL,
    CHANGE_WORKER_PASSWORD_RESET,
  } = WORKERS;
  const {
    workers,
    workersRequestsCount,
    applyNewFilter,
    index,
    workerDetails,
    isOpen,
    newWorkerInfo,
    editParams,
    workerStatistics,
    workerMotivationAndPunishments,
    mode,
    err,
  } = payload;
  const {
    workers: workersState,
    workerDetails: workerDetailsState,
    workersRequestsCount: workersRequestsCountState,
    addNewWorkerModal: { isOpen: addNewWorkerModalIsOpenState },
    editWorkerModal: { isOpen: editWorkerModalIsOpenState },
    changeWorkerPasswordModal: { isOpen: changeWorkerPasswordModalIsOpenState },
  } = state;

  switch (type) {
    case GET_ALL_WORKERS: {
      return {
        ...state,
        applyNewFilter,
        getAllWorkers: {
          isFetching: true,
          isSuccess: false,
          isFail: { isError: false, message: '' },
        },
      };
    }
    case GET_ALL_WORKERS_SUCCESS: {
      return {
        ...state,
        workers: workersState && !applyNewFilter ? unionBy(workersState, workers, '_id') : workers,
        hasMoreWorkers: workers.length === 20,
        getAllWorkers: {
          isFetching: false,
          isSuccess: true,
          isFail: { isError: false, message: '' },
        },
      };
    }
    case GET_ALL_WORKERS_FAIL: {
      return {
        ...state,
        getAllWorkers: {
          isFetching: false,
          isSuccess: false,
          isFail: { isError: true, message: err },
        },
      };
    }
    case ADD_WORKER: {
      return {
        ...state,
        addWorker: {
          isFetching: true,
          isSuccess: false,
          isFail: { isError: false, message: '' },
        },
      };
    }
    case ADD_WORKER_SUCCESS: {
      workersState.unshift(newWorkerInfo);

      return {
        ...state,
        workers: workersState,
        addWorker: {
          isFetching: false,
          isSuccess: true,
          isFail: { isError: false, message: '' },
        },
        addNewWorkerModal: { isOpen },
      };
    }
    case ADD_WORKER_FAIL: {
      return {
        ...state,
        addWorker: {
          isFetching: false,
          isSuccess: false,
          isFail: { isError: true, message: err },
        },
      };
    }
    case ADD_WORKER_RESET: {
      return {
        ...state,
        addWorker: {
          isFetching: false,
          isSuccess: false,
          isFail: { isError: false, message: '' },
        },
      };
    }
    case EDIT_WORKER: {
      return {
        ...state,
        editWorker: {
          isFetching: true,
          isSuccess: false,
          isFail: { isError: false, message: '' },
        },
      };
    }
    case EDIT_WORKER_SUCCESS: {
      const updatedWorkers =
        index !== undefined
          ? update(workersState, { [index]: { $set: { ...workersState[index], ...editParams } } })
          : workersState;
      const updatedWorkerDetails =
        index !== undefined ? workerDetailsState : { ...workerDetailsState, ...editParams };

      return {
        ...state,
        workers: updatedWorkers,
        workerDetails: updatedWorkerDetails,
        editWorker: {
          isFetching: false,
          isSuccess: true,
          isFail: { isError: false, message: '' },
        },
        editWorkerModal: { isOpen },
      };
    }
    case EDIT_WORKER_FAIL: {
      return {
        ...state,
        editWorker: {
          isFetching: false,
          isSuccess: false,
          isFail: { isError: true, message: err },
        },
      };
    }
    case EDIT_WORKER_RESET: {
      return {
        ...state,
        editWorker: {
          isFetching: false,
          isSuccess: false,
          isFail: { isError: false, message: '' },
        },
      };
    }
    case GET_WORKER_DETAILS: {
      return {
        ...state,
        getWorkerDetails: {
          isFetching: true,
          isSuccess: false,
          isFail: { isError: false, message: '' },
        },
      };
    }
    case GET_WORKER_DETAILS_SUCCESS: {
      return {
        ...state,
        workerDetails,
        getWorkerDetails: {
          isFetching: false,
          isSuccess: true,
          isFail: { isError: false, message: '' },
        },
      };
    }
    case GET_WORKER_DETAILS_FAIL: {
      return {
        ...state,
        getWorkerDetails: {
          isFetching: false,
          isSuccess: false,
          isFail: { isError: true, message: err },
        },
      };
    }
    case TOGGLE_ADD_NEW_WORKER_MODAL: {
      if (mode === 'edit') {
        return {
          ...state,
          editWorkerModal: {
            isOpen: !editWorkerModalIsOpenState,
          },
        };
      }
      return {
        ...state,
        addNewWorkerModal: {
          isOpen: !addNewWorkerModalIsOpenState,
        },
      };
    }
    case GET_WORKERS_REQUESTS_COUNT: {
      return {
        ...state,
        applyNewFilter,
        getWorkersRequestsCount: {
          isFetching: true,
          isSuccess: false,
          isFail: { isError: false, message: '' },
        },
      };
    }
    case GET_WORKERS_REQUESTS_COUNT_SUCCESS: {
      return {
        ...state,
        applyNewFilter: false,
        workersRequestsCount:
          workersRequestsCountState && !applyNewFilter
            ? unionBy(workersRequestsCountState, workersRequestsCount, '_id')
            : workersRequestsCount,
        hasMoreWorkersRequestsCount: workersRequestsCount.length === 20,
        getWorkersRequestsCount: {
          isFetching: false,
          isSuccess: true,
          isFail: { isError: false, message: '' },
        },
      };
    }
    case GET_WORKERS_REQUESTS_COUNT_FAIL: {
      return {
        ...state,
        getWorkersRequestsCount: {
          isFetching: false,
          isSuccess: false,
          isFail: { isError: true, message: err },
        },
      };
    }
    case GET_WORKER_STATISTICS: {
      return {
        ...state,
        getWorkerStatistics: {
          isFetching: true,
          isSuccess: false,
          isFail: { isError: false, message: '' },
        },
      };
    }
    case GET_WORKER_STATISTICS_SUCCESS: {
      return {
        ...state,
        getWorkerStatistics: {
          isFetching: false,
          isSuccess: true,
          isFail: { isError: false, message: '' },
        },
        workerStatistics,
      };
    }
    case GET_WORKER_STATISTICS_FAIL: {
      return {
        ...state,
        getWorkerStatistics: {
          isFetching: false,
          isSuccess: false,
          isFail: { isError: true, message: err },
        },
      };
    }
    case GET_WORKER_MOTIVATIONS_AND_PUNISHMENTS: {
      return {
        ...state,
        getWorkerMotivationAndPunishments: {
          isFetching: true,
          isSuccess: false,
          isFail: { isError: false, message: '' },
        },
      };
    }
    case GET_WORKER_MOTIVATIONS_AND_PUNISHMENTS_SUCCESS: {
      return {
        ...state,
        getWorkerMotivationAndPunishments: {
          isFetching: false,
          isSuccess: true,
          isFail: { isError: false, message: '' },
        },
        workerMotivationAndPunishments,
      };
    }
    case GET_WORKER_MOTIVATIONS_AND_PUNISHMENTS_FAIL: {
      return {
        ...state,
        getWorkerMotivationAndPunishments: {
          isFetching: false,
          isSuccess: false,
          isFail: { isError: true, message: err },
        },
      };
    }
    case TOGGLE_CHANGE_WORKER_PASSWORD_MODAL: {
      return {
        ...state,
        changeWorkerPasswordModal: {
          isOpen: !changeWorkerPasswordModalIsOpenState,
        },
      };
    }
    case CHANGE_WORKER_PASSWORD: {
      return {
        ...state,
        changeWorkerPassword: {
          isFetching: true,
          isSuccess: false,
          isFail: { isError: false, message: '' },
        },
      };
    }
    case CHANGE_WORKER_PASSWORD_SUCCESS: {
      return {
        ...state,
        changeWorkerPassword: {
          isFetching: false,
          isSuccess: true,
          isFail: { isError: false, message: '' },
        },
        changeWorkerPasswordModal: { isOpen },
      };
    }
    case CHANGE_WORKER_PASSWORD_FAIL: {
      return {
        ...state,
        changeWorkerPassword: {
          isFetching: false,
          isSuccess: false,
          isFail: { isError: true, message: err },
        },
      };
    }
    case CHANGE_WORKER_PASSWORD_RESET: {
      return {
        ...state,
        changeWorkerPassword: {
          isFetching: false,
          isSuccess: false,
          isFail: { isError: false, message: '' },
        },
      };
    }
    default:
      return state;
  }
};
