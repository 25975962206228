import { takeEvery, takeLatest, put } from 'redux-saga/effects';
import Cookies from 'js-cookie';
import { Api, Urls, Status } from 'utils/api';
import { COOKIES_KEYS } from 'utils/constants';
import { WORKERS } from './actions';

const {
  GET_ALL_WORKERS,
  GET_ALL_WORKERS_SUCCESS,
  GET_ALL_WORKERS_FAIL,
  ADD_WORKER,
  ADD_WORKER_SUCCESS,
  ADD_WORKER_FAIL,
  EDIT_WORKER,
  EDIT_WORKER_SUCCESS,
  EDIT_WORKER_FAIL,
  GET_WORKER_DETAILS,
  GET_WORKER_DETAILS_SUCCESS,
  GET_WORKER_DETAILS_FAIL,
  GET_WORKERS_REQUESTS_COUNT,
  GET_WORKERS_REQUESTS_COUNT_SUCCESS,
  GET_WORKERS_REQUESTS_COUNT_FAIL,
  GET_WORKER_STATISTICS,
  GET_WORKER_STATISTICS_SUCCESS,
  GET_WORKER_STATISTICS_FAIL,
  GET_WORKER_MOTIVATIONS_AND_PUNISHMENTS,
  GET_WORKER_MOTIVATIONS_AND_PUNISHMENTS_SUCCESS,
  GET_WORKER_MOTIVATIONS_AND_PUNISHMENTS_FAIL,
  CHANGE_WORKER_PASSWORD,
  CHANGE_WORKER_PASSWORD_SUCCESS,
  CHANGE_WORKER_PASSWORD_FAIL,
} = WORKERS;

function* getAllWorkers(payload) {
  const { options, applyNewFilter } = payload;
  const { TOKEN } = COOKIES_KEYS;
  const token = Cookies.get(TOKEN);
  const { workers } = Urls;
  const api = new Api();
  const header = [
    {
      key: 'token',
      value: token,
    },
  ];

  let url = workers.getAllWorkers;

  Object.keys(options).forEach(key => {
    url = `${url}&${key}=${options[key]}`;
  });

  const response = yield api.get(url, header);

  if (Status.isSuccess(response.status)) {
    const { response: allWorkers } = response;

    yield put({
      type: GET_ALL_WORKERS_SUCCESS,
      workers: allWorkers,
      applyNewFilter,
    });
  } else {
    const {
      response: { message },
    } = response;

    yield put({
      type: GET_ALL_WORKERS_FAIL,
      err: message,
    });
  }
}

function* addWorker(payload) {
  const { newWorkerInfo } = payload;
  const { TOKEN } = COOKIES_KEYS;
  const token = Cookies.get(TOKEN);
  const { workers } = Urls;
  const url = `${workers.signupWorker}`;
  const api = new Api();
  const body = {
    ...newWorkerInfo,
  };
  const header = [
    {
      key: 'token',
      value: token,
    },
  ];
  const response = yield api.post(url, body, header);

  if (Status.isSuccess(response.status)) {
    const { response: newWorkerInfoResponse } = response;

    yield put({
      type: ADD_WORKER_SUCCESS,
      newWorkerInfo: newWorkerInfoResponse,
    });
  } else {
    const {
      response: { message },
    } = response;

    yield put({
      type: ADD_WORKER_FAIL,
      err: message,
    });
  }
}

function* editWorker(payload) {
  const { workerId, index, editParams } = payload;
  const { TOKEN } = COOKIES_KEYS;
  const token = Cookies.get(TOKEN);
  const { user } = Urls;
  const url = `${user.general}/${workerId}`;
  const api = new Api();
  const vendorId = editParams.vendor ? editParams.vendor._id : null;
  const body = {
    ...editParams,
    vendor: vendorId,
  };
  const header = [
    {
      key: 'token',
      value: token,
    },
  ];
  const response = yield api.patch(url, body, header);

  if (Status.isSuccess(response.status)) {
    yield put({
      type: EDIT_WORKER_SUCCESS,
      index,
      editParams,
    });
  } else {
    const {
      response: { message },
    } = response;

    yield put({
      type: EDIT_WORKER_FAIL,
      err: message,
    });
  }
}

function* getWorkerDetails(payload) {
  const { workerUserName } = payload;
  const { TOKEN } = COOKIES_KEYS;
  const token = Cookies.get(TOKEN);
  const { workers } = Urls;
  const url = `${workers.getAllWorkers}&limit=1&skip=0&partialUsername=${workerUserName}`;
  const api = new Api();
  const header = [
    {
      key: 'token',
      value: token,
    },
  ];
  const response = yield api.get(url, header);

  if (Status.isSuccess(response.status)) {
    const { response: workersArray } = response;
    const workerDetails = workersArray[0];

    yield put({
      type: GET_WORKER_DETAILS_SUCCESS,
      workerDetails,
    });
  } else {
    const {
      response: { message },
    } = response;

    yield put({
      type: GET_WORKER_DETAILS_FAIL,
      err: message,
    });
  }
}

function* getWorkersPayments(payload) {
  const { options, applyNewFilter } = payload;
  const { TOKEN } = COOKIES_KEYS;
  const token = Cookies.get(TOKEN);
  const { workers } = Urls;
  const api = new Api();
  const header = [
    {
      key: 'token',
      value: token,
    },
  ];
  let url = workers.getWorkersRequestsCount;

  Object.keys(options).forEach((key, index) => {
    if (index === 0) {
      url = `${url}?${key}=${options[key]}`;
    } else {
      url = `${url}&${key}=${options[key]}`;
    }
  });

  const response = yield api.get(url, header);

  if (Status.isSuccess(response.status)) {
    const { response: workersRequestsCount } = response;

    yield put({
      type: GET_WORKERS_REQUESTS_COUNT_SUCCESS,
      workersRequestsCount,
      applyNewFilter,
    });
  } else {
    const {
      response: { message },
    } = response;

    yield put({
      type: GET_WORKERS_REQUESTS_COUNT_FAIL,
      err: message,
    });
  }
}

function* getWorkerStatistics(payload) {
  const { workerId } = payload;
  const { TOKEN } = COOKIES_KEYS;
  const token = Cookies.get(TOKEN);
  const api = new Api();
  const header = [
    {
      key: 'token',
      value: token,
    },
  ];
  const { workers } = Urls;
  const options = { workerId };
  let url = workers.statistics;

  Object.keys(options).forEach((key, index) => {
    if (index === 0) {
      url = `${url}?${key}=${options[key]}`;
    } else {
      url = `${url}&${key}=${options[key]}`;
    }
  });
  const response = yield api.get(url, header);

  if (Status.isSuccess(response.status)) {
    const { response: workerStatistics } = response;
    yield put({
      type: GET_WORKER_STATISTICS_SUCCESS,
      workerStatistics,
    });
  } else {
    const {
      response: { message },
    } = response;

    yield put({
      type: GET_WORKER_STATISTICS_FAIL,
      err: message,
    });
  }
}

function* getWorkerMotivationsAndPunishments(payload) {
  const { workerId } = payload;
  const { TOKEN } = COOKIES_KEYS;
  const token = Cookies.get(TOKEN);
  const api = new Api();
  const header = [
    {
      key: 'token',
      value: token,
    },
  ];
  const { workers } = Urls;
  const options = { workerId };
  let url = workers.motivationsAndPunishments;

  Object.keys(options).forEach((key, index) => {
    if (index === 0) {
      url = `${url}?${key}=${options[key]}`;
    } else {
      url = `${url}&${key}=${options[key]}`;
    }
  });
  const response = yield api.get(url, header);

  if (Status.isSuccess(response.status)) {
    const { response: workerMotivationAndPunishments } = response;
    yield put({
      type: GET_WORKER_MOTIVATIONS_AND_PUNISHMENTS_SUCCESS,
      workerMotivationAndPunishments,
    });
  } else {
    const {
      response: { message },
    } = response;

    yield put({
      type: GET_WORKER_MOTIVATIONS_AND_PUNISHMENTS_FAIL,
      err: message,
    });
  }
}

function* changeWorkerPassword(payload) {
  const { newPasswordInfo } = payload;
  const { TOKEN } = COOKIES_KEYS;
  const token = Cookies.get(TOKEN);
  const { workers } = Urls;
  const url = workers.changeWorkerPassword;
  const api = new Api();
  const body = {
    ...newPasswordInfo,
  };
  const header = [
    {
      key: 'token',
      value: token,
    },
  ];
  const response = yield api.post(url, body, header);

  if (Status.isSuccess(response.status)) {
    yield put({
      type: CHANGE_WORKER_PASSWORD_SUCCESS,
    });
  } else {
    const {
      response: { message },
    } = response;

    yield put({
      type: CHANGE_WORKER_PASSWORD_FAIL,
      err: message,
    });
  }
}

function* workersSaga() {
  yield takeLatest(GET_ALL_WORKERS, getAllWorkers);
  yield takeEvery(ADD_WORKER, addWorker);
  yield takeEvery(EDIT_WORKER, editWorker);
  yield takeEvery(GET_WORKER_DETAILS, getWorkerDetails);
  yield takeLatest(GET_WORKERS_REQUESTS_COUNT, getWorkersPayments);
  yield takeLatest(GET_WORKER_STATISTICS, getWorkerStatistics);
  yield takeLatest(GET_WORKER_MOTIVATIONS_AND_PUNISHMENTS, getWorkerMotivationsAndPunishments);
  yield takeEvery(CHANGE_WORKER_PASSWORD, changeWorkerPassword);
}

export default workersSaga;
