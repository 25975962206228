import { takeLatest, put } from 'redux-saga/effects';
import Cookies from 'js-cookie';
import { Api, Urls, Status } from 'utils/api';
import { COOKIES_KEYS } from 'utils/constants';
import { VENDORS } from './actions';

const { GET_VENDOR_DETAILS, GET_VENDOR_DETAILS_SUCCESS, GET_VENDOR_DETAILS_FAIL } = VENDORS;

function* getVendorDetails(payload) {
  const { vendorId } = payload;
  const { TOKEN } = COOKIES_KEYS;
  const token = Cookies.get(TOKEN);
  const { vendors } = Urls;
  const api = new Api();
  const header = [
    {
      key: 'token',
      value: token,
    },
  ];

  const url = `${vendors.findVendorById}/${vendorId}`;
  const response = yield api.get(url, header);

  if (Status.isSuccess(response.status)) {
    const { response: vendorDetails } = response;
    console.log('vendorDetails', vendorDetails);
    yield put({
      type: GET_VENDOR_DETAILS_SUCCESS,
      vendorDetails,
    });
  } else {
    const {
      response: { message },
    } = response;

    yield put({
      type: GET_VENDOR_DETAILS_FAIL,
      err: message,
    });
  }
}

function* workersSaga() {
  yield takeLatest(GET_VENDOR_DETAILS, getVendorDetails);
}

export default workersSaga;
