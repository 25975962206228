import { takeEvery, takeLatest, put, race, call, take, fork } from 'redux-saga/effects';
import { eventChannel, delay } from 'redux-saga';
import Cookies from 'js-cookie';
import { COOKIES_KEYS } from 'utils/constants';
import socketIOClient from 'socket.io-client';
import { Api, Urls, Status } from '../../utils/api';
import { REQUESTS } from './actions';

const {
  GET_ALL_REQUESTS,
  GET_ALL_REQUESTS_SUCCESS,
  GET_ALL_REQUESTS_FAIL,
  GET_REQUEST_DETAILS_BY_PRETTY_ID,
  GET_REQUEST_DETAILS_BY_PRETTY_ID_SUCCESS,
  GET_REQUEST_DETAILS_BY_PRETTY_ID_FAIL,
  GET_REQUESTS_PAYMENTS,
  GET_REQUESTS_PAYMENTS_SUCCESS,
  GET_REQUESTS_PAYMENTS_FAIL,
  CONNECT_TO_SOCKET_IO,
  NEW_REQUEST_CREATED,
  SOCKET_IO_SERVER_ON,
  SOCKET_IO_SERVER_OFF,
  REQUEST_UPDATED,
  GET_RECONDITIONING_REQUESTS_COUNT,
  GET_RECONDITIONING_REQUESTS_COUNT_SUCCESS,
  GET_RECONDITIONING_REQUESTS_COUNT_FAIL,
} = REQUESTS;
let socket;

function* getAllRequests(payload) {
  const { options, applyNewFilter } = payload;
  const { TOKEN } = COOKIES_KEYS;
  const token = Cookies.get(TOKEN);
  const { requests } = Urls;
  const api = new Api();
  const header = [
    {
      key: 'token',
      value: token,
    },
  ];

  let url = requests.getAllRequests;

  Object.keys(options).forEach((key, index) => {
    if (index === 0) {
      url = `${url}?${key}=${options[key]}`;
    } else {
      url = `${url}&${key}=${options[key]}`;
    }
  });

  const response = yield api.get(url, header);

  if (Status.isSuccess(response.status)) {
    const { response: allRequests } = response;

    yield put({
      type: GET_ALL_REQUESTS_SUCCESS,
      requests: allRequests,
      applyNewFilter,
    });
  } else {
    const {
      response: { message },
    } = response;

    yield put({
      type: GET_ALL_REQUESTS_FAIL,
      err: message,
    });
  }
}

function* getRequestDetails(payload) {
  const { requestPrettyId } = payload;
  const { TOKEN } = COOKIES_KEYS;
  const token = Cookies.get(TOKEN);
  const { requests } = Urls;
  const url = `${requests.getAllRequests}/prettyId?requestPrettyId=${requestPrettyId}`;
  const api = new Api();
  const header = [
    {
      key: 'token',
      value: token,
    },
  ];

  const response = yield api.get(url, header);

  if (Status.isSuccess(response.status)) {
    const { response: requestDetails } = response;

    yield put({
      type: GET_REQUEST_DETAILS_BY_PRETTY_ID_SUCCESS,
      requestDetails,
    });
  } else {
    const {
      response: { message },
    } = response;

    yield put({
      type: GET_REQUEST_DETAILS_BY_PRETTY_ID_FAIL,
      err: message,
    });
  }
}

function* getRequestsPayments(payload) {
  const { options, applyNewFilter } = payload;
  const { TOKEN } = COOKIES_KEYS;
  const token = Cookies.get(TOKEN);
  const { requests } = Urls;
  const api = new Api();
  const header = [
    {
      key: 'token',
      value: token,
    },
  ];
  let url = requests.getRequestsPayments;

  Object.keys(options).forEach((key, index) => {
    if (index === 0) {
      url = `${url}?${key}=${options[key]}`;
    } else {
      url = `${url}&${key}=${options[key]}`;
    }
  });

  const response = yield api.get(url, header);

  if (Status.isSuccess(response.status)) {
    const { response: allRequestsPayments } = response;

    yield put({
      type: GET_REQUESTS_PAYMENTS_SUCCESS,
      requestsPayments: allRequestsPayments,
      applyNewFilter,
    });
  } else {
    const {
      response: { message },
    } = response;

    yield put({
      type: GET_REQUESTS_PAYMENTS_FAIL,
      err: message,
    });
  }
}

function connectToSocketIo() {
  const { requests } = Urls;
  const url = requests.getAllRequests;

  socket = socketIOClient(url);
  return new Promise(resolve => {
    socket.on('connect', () => {
      resolve(socket);
    });
  });
}

const reconnect = () => {
  const { requests } = Urls;
  const url = requests.getAllRequests;

  socket = socketIOClient(url);
  return new Promise(resolve => {
    socket.on('reconnect', () => {
      resolve(socket);
    });
  });
};

const disconnect = () => {
  const { requests } = Urls;
  const url = requests.getAllRequests;

  socket = socketIOClient(url);
  return new Promise(resolve => {
    socket.on('disconnect', () => {
      resolve(socket);
    });
  });
};

const createSocketChannel = sendSocket =>
  eventChannel(emit => {
    sendSocket.on('newRequest', data => {
      emit({ type: NEW_REQUEST_CREATED, data, key: 'newRequest' });
    });

    sendSocket.on('requestUpdated', data => {
      emit({ type: REQUEST_UPDATED, data, key: 'updatedRequest' });
    });
    return () => {
      sendSocket.off('newRequest', data => {
        emit({ type: NEW_REQUEST_CREATED, data });
      });
    };
  });

const listenDisconnectSaga = function*() {
  while (true) {
    yield call(disconnect);
    yield put({ type: SOCKET_IO_SERVER_OFF });
  }
};

const listenConnectSaga = function*() {
  while (true) {
    yield call(reconnect);
    yield put({ type: SOCKET_IO_SERVER_ON });
  }
};

function* connect() {
  try {
    const { timeout } = yield race({
      connected: call(connectToSocketIo),
      timeout: delay(20000),
    });

    if (timeout) {
      yield put({ type: SOCKET_IO_SERVER_OFF });
    }

    const createdSocket = yield call(connectToSocketIo);
    const socketChannel = yield call(createSocketChannel, createdSocket);
    yield fork(listenDisconnectSaga);
    yield fork(listenConnectSaga);
    yield put({ type: SOCKET_IO_SERVER_ON });
    while (true) {
      const payload = yield take(socketChannel);
      yield put({ type: payload.type, [payload.key]: payload.data });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getReconditioningRequestsCount(payload) {
  const { options } = payload;
  const { TOKEN } = COOKIES_KEYS;
  const token = Cookies.get(TOKEN);
  const { requests } = Urls;
  const api = new Api();
  let url = requests.reconditioningCount;
  const header = [
    {
      key: 'token',
      value: token,
    },
  ];

  Object.keys(options).forEach((key, index) => {
    if (index === 0) {
      url = `${url}?${key}=${options[key]}`;
    } else {
      url = `${url}&${key}=${options[key]}`;
    }
  });
  const response = yield api.get(url, header);
  const responseStatus = response.status;
  const reconditioningRequestsCount = response.response;

  if (Status.isSuccess(responseStatus)) {
    yield put({
      type: GET_RECONDITIONING_REQUESTS_COUNT_SUCCESS,
      reconditioningRequestsCount,
    });
  } else {
    const {
      response: { message },
    } = response;

    yield put({
      type: GET_RECONDITIONING_REQUESTS_COUNT_FAIL,
      err: message,
    });
  }
}

function* requestsSaga() {
  yield takeLatest(GET_ALL_REQUESTS, getAllRequests);
  yield takeEvery(GET_REQUEST_DETAILS_BY_PRETTY_ID, getRequestDetails);
  yield takeLatest(GET_REQUESTS_PAYMENTS, getRequestsPayments);
  yield takeEvery(CONNECT_TO_SOCKET_IO, connect);
  yield takeEvery(GET_RECONDITIONING_REQUESTS_COUNT, getReconditioningRequestsCount);
}

export default requestsSaga;
