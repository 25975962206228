const BaseUrls = {
  apiUrl: `${process.env.REACT_APP_API_URL}`,
};

const Urls = {
  user: {
    login: `${BaseUrls.apiUrl}/users/vendorLogin`,
    deleteUser: `${BaseUrls.apiUrl}/users/deleteUser`,
    suspendUser: `${BaseUrls.apiUrl}/users/changeSuspend`,
    general: `${BaseUrls.apiUrl}/users`,
    targetNotification: `${BaseUrls.apiUrl}/users/targetNotification`,
    comments: `${BaseUrls.apiUrl}/users/comments`,
    createAdmin: `${BaseUrls.apiUrl}/users/createAdmin`,
    changeAdminPassword: `${BaseUrls.apiUrl}/users/resetAdminPassword`,
    addPermissionsToUser: `${BaseUrls.apiUrl}/users/attachPermissionsToUser`,
    usersCounts: `${BaseUrls.apiUrl}/users/usersCounts`,
  },
  requests: {
    getAllRequests: `${BaseUrls.apiUrl}/requests`,
    getRequestsPayments: `${BaseUrls.apiUrl}/report/requestPayment`,
    cancelRequest: `${BaseUrls.apiUrl}/requests/cancelRequestByAdmin`,
    comments: `${BaseUrls.apiUrl}/requests/comments`,
    tickets: `${BaseUrls.apiUrl}/requests/tickets`,
    exportRequestsPayments: `${BaseUrls.apiUrl}/report/download/requestPayment`,
    getRequestsCounts: `${BaseUrls.apiUrl}/requests/counts`,
    getRequestsCostStatistics: `${BaseUrls.apiUrl}/requests/costStatistics`,
    addRequestFollowUps: `${BaseUrls.apiUrl}/requests/requestFollowUps`,
    locations: `${BaseUrls.apiUrl}/requests/locations`,
    addNewRequest: `${BaseUrls.apiUrl}/requests/adminMakeRequest`,
    finishRequest: `${BaseUrls.apiUrl}/requests/costDetails/confirm`,
    finishForReconditioningField: `${BaseUrls.apiUrl}/requests/doneByAdminForReconditioning`,
    activate: `${BaseUrls.apiUrl}/requests/accept`,
    reconditioningCount: `${BaseUrls.apiUrl}/requests/countsReconditioningRequests`,
  },
  clients: {
    getAllClients: `${BaseUrls.apiUrl}/users?types=customer`,
    getClientsPayments: `${BaseUrls.apiUrl}/report/clientPayment`,
    convertClientToWorker: `${BaseUrls.apiUrl}/users/convertCustomerToWorker`,
    exportClientsPayments: `${BaseUrls.apiUrl}/report/download/clientPayment`,
    clientsReport: `${BaseUrls.apiUrl}/report/usersData`,
    exportClients: `${BaseUrls.apiUrl}/report/download/usersData?role=customer`,
  },
  workers: {
    signupWorker: `${BaseUrls.apiUrl}/users/workerSignUp`,
    getAllWorkers: `${BaseUrls.apiUrl}/users?types=worker`,
    getWorkersRequestsCount: `${BaseUrls.apiUrl}/report/getWorkersRequestsCount`,
    activateWorker: `${BaseUrls.apiUrl}/users/activateWorker`,
    changeWorkerPassword: `${BaseUrls.apiUrl}/users/setWorkerPassword`,
    exportWorkersRequestsCount: `${BaseUrls.apiUrl}/report/download/workersRequestsCount`,
    locations: `${BaseUrls.apiUrl}/users/workersWithinArea`,
    rechargeCost: `${BaseUrls.apiUrl}/report/workers/recharge`,
    exportWorkers: `${BaseUrls.apiUrl}/report/download/usersData?role=worker`,
    subscribeToAutoApply: `${BaseUrls.apiUrl}/transactions/renewal/autoApply`,
    subscribeToMultiFields: `${BaseUrls.apiUrl}/transactions/renewal/multiFields`,
    workersInEachField: `${BaseUrls.apiUrl}/users/workersCounts`,
    statistics: `${BaseUrls.apiUrl}/requests/worker/statistics`,
    motivationsAndPunishments: `${BaseUrls.apiUrl}/users/motivationsAndPunishments`,
    finishAutoApplySubscription: `${BaseUrls.apiUrl}/users/finishAutoApplySubscription`,
    finishMultiFieldsSubscription: `${BaseUrls.apiUrl}/users/finishMultiFieldsSubscription`,
  },
  transactions: {
    getAllTransactions: `${BaseUrls.apiUrl}/transactions`,
    getWorkerTransactions: `${BaseUrls.apiUrl}/transactions/filterByWorker`,
    getClassification: `${BaseUrls.apiUrl}/transactions/custom/classification`,
    addWorkerCredit: `${BaseUrls.apiUrl}/transactions/custom`,
    addClientCredit: `${BaseUrls.apiUrl}/transactions/customerCustom`,
    exportWorkersTransactions: `${BaseUrls.apiUrl}/report/download/transactionsReportForAllWorkers`,
  },
  reviews: {
    general: `${BaseUrls.apiUrl}/reviews`,
  },
  tickets: {
    general: `${BaseUrls.apiUrl}/tickets`,
    ticketsCountInEachStatus: `${BaseUrls.apiUrl}/tickets/count`,
  },
  promoCodes: {
    general: `${BaseUrls.apiUrl}/promoCodes`,
    promoCodeReport: `${BaseUrls.apiUrl}/report/promoCode`,
    exportPromoCodeReport: `${BaseUrls.apiUrl}/report/download/promoCode`,
  },
  configs: {
    getConfigs: `${BaseUrls.apiUrl}/misc/configs/dashboard`,
  },
  messages: {
    getAllMessages: `${BaseUrls.apiUrl}/workerMessages`,
    addNewMessage: `${BaseUrls.apiUrl}/workerMessages`,
    delete: `${BaseUrls.apiUrl}/workerMessages`,
    update: `${BaseUrls.apiUrl}/workerMessages`,
  },
  activitiesLogs: {
    getAllActivitiesLogs: `${BaseUrls.apiUrl}/activityLogs`,
  },
  reports: {
    financialByFields: `${BaseUrls.apiUrl}/report/financial/fields`,
    downloadFinancialByFields: `${BaseUrls.apiUrl}/report/download/financial/fields`,
    financialByCities: `${BaseUrls.apiUrl}/report/financial/cities`,
    downloadFinancialByCities: `${BaseUrls.apiUrl}/report/download/financial/cities`,
    workersCredits: `${BaseUrls.apiUrl}/report/workers/credits`,
    cities: `${BaseUrls.apiUrl}/report/cities`,
  },
  vendors: {
    getAllVendors: `${BaseUrls.apiUrl}/vendors`,
    findVendorById: `${BaseUrls.apiUrl}/vendors`,
    delete: `${BaseUrls.apiUrl}/vendors`,
    addNewVendor: `${BaseUrls.apiUrl}/vendors`,
    update: `${BaseUrls.apiUrl}/vendors`,
    changePassword: `${BaseUrls.apiUrl}/vendors/password/change`,
  },
};

export default Urls;
